import React from 'react';
import * as Sentry from "@sentry/react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { change } from 'redux-form';
import { Typography, Grid, Container, Box, IconButton, Paper } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Table from '../components/Table';
import Modal from '../components/Modal';
import Spinner from '../components/Spinner';
import FormRechazoCotizacion from '../components/FormRechazoCotizacion';
import FormSepararItems from '../components/FormSepararItems';
import FormCotizacion from '../components/FormCotizacion';
import FormItemRecibido from '../components/FormItemRecibido';
import FormCargarFactura from '../containers/FormCargarFactura';
import BotonMenuItems from '../components/BotonMenuItems';
import BotonMenuCotizaciones from '../components/BotonMenuCotizaciones';
import FormProveedores from '../components/FormProveedores';
import * as solicitudesUsuarioDuck from '../ducks/SolicitudesUsuario';
import * as solicitudesAprobarDuck from '../ducks/SolicitudesAprobar';
import * as solicitudesDuck from '../ducks/Solicitudes';
import * as idsolicitudDuck from '../ducks/Idsolicitud';
import * as iditemDuck from '../ducks/Iditem';
import * as itemsDuck from '../ducks/Items';
import * as aprobador1Duck from '../ducks/Aprobador1';
import * as aprobador2Duck from '../ducks/Aprobador2';
import * as vehiculoDuck from '../ducks/Vehiculos';
import * as mensajesDuck from '../ducks/Mensajes';
import * as modoEdicionDuck from '../ducks/ModoEdicion';
import * as gruposCotizacionDuck from '../ducks/GruposCotizacion';
import * as cotizacionesDuck from '../ducks/Cotizaciones';
import * as ordenesCompraDuck from '../ducks/OrdenesCompra';
import * as idGrupoCotizacionDuck from '../ducks/IdGrupoCotizacion';
import * as itemsCotizacionDuck from '../ducks/ItemsCotizacion';
import * as archivoDuck from '../ducks/Archivo';
import * as itemsCotizadosDuck from '../ducks/ItemsCotizados';
import * as proyectosGeneralDuck from '../ducks/ProyectosGeneral';
import * as gerenciasGeneralDuck from '../ducks/GerenciasGeneral';
import * as comprobanteRecepcionDuck from '../ducks/ComprobanteRecepcion';
import * as trasladoItemRecursoDuck from '../ducks/TrasladoItemRecurso';
import * as entregaItemRecurso from '../ducks/EntregaItemRecurso';
import * as proveedoresDuck from '../ducks/Proveedores';
import * as ciudadesDuck from '../ducks/Ciudades';
import * as aprobadoresGerenciaDuck from '../ducks/AprobadoresGerencia';
import { saveAs } from 'file-saver';
import { addMillsSep, downloadUrlAsPromise, uploadFileAsPromise, formatoRut, uploadFileToCloudStorage } from '../services/utils';
import { getCurrentUser } from '../services/auth';
import { leer } from '../services/api';
import moment from 'moment';
import 'moment/locale/es';
import VerCotizacion from './VerCotizacion';
import { APROBADOR_GERENTE_GAF } from '../utils/constants';
moment.locale('es');

class AdquisicionCotizacion extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            openModalRechazo: false,
            openModalSeparar: false,
            openModalEntregar: false,
            openCrearCotizacion: false,
            openVerCotizacion: false,
            openItemTraslado: false,
            openItemRecibido: false,
            openCargarFactura: false,
            openListoEntrega: false,
            openFormProveedor: false,
            loadingFormProveedor: false,
            loadingItemRecibido: false,
            confirmacion: false,
            accion: null,
            loading: false,
            uploadingFactura: false,
            mostrarFormulario: true,
            mostrarBusqueda: true,
            mostrarDetalle: false,
            loadingDetalle: true,
            comprobante: '',
            nomArchivoFactura: '',
            cantidadItemsEntregados: 0,
            textoItemsEntregados: '',
            maxItemsEntrega: 0,
            cantidadItemsRecibidos: 0,
            textoItemsRecibidos: '',
            maxItemsRecibidos: 0,
            textoItemsEnTraslado: '',
            maxItemsEnTraslado: 0,
            tipoRecepcion: 'unitario',
            listaItemsCotizados: {},
            itemsARecepcionar: [],
            todoRecibido: false,
            descripcionFactura: '',
            loadingCotizacion: false,
            mostrarAyudaPDFProtegido: false,
            idCotizacion: null,
            recepcionRealizada: false,
            empresas: [],
            centrosAll: [],
            centrosUsuario: [],
            paises: [],
        };
    }

    componentDidMount = async () => {
        const {
            // cotizaciones,
            // fetchItemsCotizados,
            // items,
            // ciudades,
            // fetchCiudades,
            solicitudActual,
            fetchAprobadoresGerencia,
            usuarioAutenticado,
        } = this.props;
        var nuevaLista = {...this.state.listaItemsCotizados};
        solicitudActual.cotizaciones.forEach(x => {
            var listaItems = [];
            var idCoti;
            x.item_cotizacion.forEach(y => {
                idCoti = y.cotizacion_id;
                listaItems.push(solicitudActual.items.find(z => z.id === y.item_recurso_id));
            });

            nuevaLista[idCoti] = listaItems;
        });
        this.setState({...this.state, listaItemsCotizados: nuevaLista});

        Promise.all([
            leer(`/empresa-cyd`),
            leer(`/centroLogisticoUsuario/${usuarioAutenticado.data.id}`),
            leer(`/centro-logistico`),
            leer(`/paises`),
        ]).then(responses => {
            this.setState({
                empresas: responses[0],
                centrosUsuario: responses[1].filter(x => x.activo),
                centrosAll: responses[2].filter(x => x.activo),
                paises: responses[3].filter(x => x.activo),
            });
        });
        fetchAprobadoresGerencia(solicitudActual.contrato._id);
    }

    //******** MODALES ************
    handleClose = () => {
        this.setState({
            ...this.state,
            openItemRecibido: false,
            openCargarFactura: false,
            openItemTraslado: false,
            // uploadingFactura: false,
            accion: false,
            confirmacion: false,
            open: false,
            openModalRechazo: false,
            openModalSeparar: false,
            openModalEntregar: false,
            openListoEntrega: false,
            mostrarAyudaPDFProtegido: false,
            idCotizacion: null,
        });
    };

    handleCloseMensaje = () => {
        this.setState({
            ...this.state,
            // openItemRecibido: false,
            // openCargarFactura: false,
            openItemTraslado: false,
            // uploadingFactura: false,
            accion: false,
            confirmacion: false,
            open: false,
            openModalRechazo: false,
            openModalSeparar: false,
            openModalEntregar: false,
            openListoEntrega: false,
            mostrarAyudaPDFProtegido: false
        });
    };

    handleCloseItemRecibido = () => {
        this.setState({
            ...this.state,
            openItemRecibido: false,
            openCargarFactura: false,
            openItemTraslado: false,
            // uploadingFactura: false,
            accion: false,
            confirmacion: false,
            open: false,
            openModalRechazo: false,
            openModalSeparar: false,
            openModalEntregar: false,
            openListoEntrega: false,
            mostrarAyudaPDFProtegido: false
        });
    };

    handleCloseCotizacion = () => {
        const { toggleModoEdicion } = this.props;
        toggleModoEdicion(false);
        this.setState({
            ...this.state,
            accion: false,
            openVerCotizacion: false,
            openCrearCotizacion: false,
            confirmacion: false,
            idCotizacion: null,
        });
    };

    //FUNCIONES PARA COTIZACIONES
    //Modal crear grupo cotizacion
    handleCrearGrupoCotizacionModal = e => {
        const { assignMensaje } = this.props;
        assignMensaje('¿Desea crear un grupo de cotización?');
        this.setState({ ...this.state, confirmacion: true, open: true, accion: this.handleCrearGrupoCotizacion });
    };

    //Crear grupoCotizacion
    handleCrearGrupoCotizacion = async () => {
        const { agregarGrupoCotizacion, idSolicitud, assignMensaje, recargarDatos } = this.props;
        await agregarGrupoCotizacion({
            solicitud_recurso_id: idSolicitud,
            fecha: moment().toISOString()
        });
        recargarDatos(false);
        assignMensaje('El grupo de cotización ha sido creado');
        this.setState({ ...this.state, confirmacion: false, open: true, accion: false });
    };

    //Modal para solicitar Aprobacion
    handleSolicitarAprobacionModal = e => {
        const { assignMensaje } = this.props;
        assignMensaje('¿Desea solicitar aprobación de sus cotizaciones?');
        this.setState({ ...this.state, confirmacion: true, open: true, accion: this.handleSolicitarAprobacion });
    };

    handleCargarFacturaModal = e => {
        this.setState({
            ...this.state,
            confirmacion: false,
            openCargarFactura: true,
            uploadingFactura: false,
            accion: false
        });
    };

    //Solicitar Aprobacion
    handleSolicitarAprobacion = async () => {
        const {
            solicitudActual,
            editarCotizaciones,
            resetItemsCotizados,
            assignMensaje,
            editarItems,
            recargarDatos,
            solicitarAprobacionCotizacion
        } = this.props;
        if (solicitudActual.cotizaciones.length > 0) {
            await Promise.all(
                solicitudActual.cotizaciones
                    .filter(x => x.activo && x.estado_id === 21)
                    .map(async x => {
                        let itemsCotizados = solicitudActual.items.filter(y => x.item_cotizacion.some(z => z.item_recurso_id === y.id));
                        await Promise.all(
                            itemsCotizados.map(async y => {
                                if (y.estado === 8) {
                                    return {
                                        items: await editarItems(y.id, {
                                            ...y,
                                            fecha_requerida: moment(y.fecha_requerida).toISOString(),
                                            estado: 22
                                        })
                                    };
                                }
                            })
                        );
                        return {
                            solicitud: await solicitarAprobacionCotizacion(x.id),
                            estado: await editarCotizaciones(x.id, {
                                estado_id: 22
                            })
                        };
                    })
            );
            recargarDatos(false);
            assignMensaje('Solicitudes de Aprobación enviadas con éxito');
            resetItemsCotizados();
            this.setState({ ...this.state, confirmacion: false, open: true, accion: false });
        } else {
            assignMensaje('No puede solicitar aprobación si no hay cotizaciones ');
            this.setState({ ...this.state, confirmacion: false, open: true, accion: false });
        }
    };

    //Modal para crear la cotizacion
    handleCrearCotizacionModal = async () => {
        const { fetchItemsCotizacion, solicitudActual, toggleModoEdicion } = this.props;
        toggleModoEdicion(false);
        await fetchItemsCotizacion(solicitudActual);
        this.setState({ ...this.state, confirmacion: false, openCrearCotizacion: true, accion: false, idCotizacion: null });
    };

    //Funcion para crear una nueva cotización
    handleCrearCotizacion = async values => {
        const {
            idGrupoCotizacion,
            toggleModoEdicion,
            editarItemsCotizados,
            solicitudActual,
            editarCotizaciones,
            agregarItemsCotizados,
            assignMensaje,
            idSolicitud,
            usuarioAutenticado,
            agregarCotizacion,
            itemsCotizados,
            recargarDatos,
            formCotizacion,
            modoEdicion,
        } = this.props;
        const impuesto = this.state.paises.find(x => x.id === values.pais).impuesto;
        let iva = (
            formCotizacion
                ? formCotizacion.values
                    ? formCotizacion.values.impuesto_incluido
                        ? false
                        : true
                    : false
                : false
        )
            ? formCotizacion
                ? formCotizacion.values
                    ? formCotizacion.values.iva
                        ? 0
                        : itemsCotizados
                              .map(x => (x.precio ? x.precio.replace(/\./g, '').replace(',', '.') * x.cantidad : 0))
                              .reduce((acc, y) => {
                                  return acc + y;
                              }) * (impuesto.porcentaje / 100)
                    : ''
                : ''
            : 0;
        let total = (
            formCotizacion
                ? formCotizacion.values
                    ? formCotizacion.values.impuesto_incluido
                        ? false
                        : true
                    : false
                : false
        )
            ? formCotizacion
                ? formCotizacion.values
                    ? formCotizacion.values.iva
                        ? itemsCotizados
                              .map(x => (x.precio ? x.precio.replace(/\./g, '').replace(',', '.') * x.cantidad : 0))
                              .reduce((acc, y) => {
                                  return acc + y;
                              })
                        : itemsCotizados
                              .map(x => (x.precio ? x.precio.replace(/\./g, '').replace(',', '.') * x.cantidad : 0))
                              .reduce((acc, y) => {
                                  return acc + y;
                              }) * (1 + (impuesto.porcentaje / 100))
                    : ''
                : ''
            : itemsCotizados
                  .map(x => (x.precio ? x.precio.replace(/\./g, '').replace(',', '.') * x.cantidad : 0))
                  .reduce((acc, y) => {
                      return acc + y;
                  });
        let cambioMoneda =
            values.tipo_moneda == 'CLP' || values.tipo_moneda == 'PEN' ? 1 : parseFloat(values.cambio_moneda.replace(/\./g, '').replace(',', '.'));
        let cambioMonedaCLP = parseFloat(values.cambioMonedaCLP.replace(/\./g, '').replace(',', '.'));
        let totalLocal = total * cambioMoneda;
        let totalPesos = totalLocal / cambioMonedaCLP;
        let itemsCotizadosCrear;
        let itemsCotizadosEditar;
        let esGastoReemb = modoEdicion 
            ? itemsCotizados.filter(x => solicitudActual?.cotizaciones?.find(
                    x => x.oldId === this.state.idCotizacion
                ).item_cotizacion.some(y => y.item_recurso_id === x.id)).some(x => x.es_gasto_reemb)
            : itemsCotizados.some(x => x.es_gasto_reemb)
        let esTarifaGestion = modoEdicion
            ? itemsCotizados.filter(x => solicitudActual?.cotizaciones?.find(
                    x => x.oldId === this.state.idCotizacion
                ).item_cotizacion.some(y => y.item_recurso_id === x.id)).some(
                    x =>
                        x.clase === 1 ||
                        x.clase === 3 ||
                        x.clase === 4 ||
                        x.clase === 5 ||
                        x.clase === 6 ||
                        x.clase === 8 ||
                        x.clase === 11
                )
            : itemsCotizados.some(
                    x =>
                        x.clase === 1 ||
                        x.clase === 3 ||
                        x.clase === 4 ||
                        x.clase === 5 ||
                        x.clase === 6 ||
                        x.clase === 8 ||
                        x.clase === 11
                );
        console.log(values, iva, total, cambioMoneda, totalPesos, esGastoReemb, esTarifaGestion);
        // return false;
        if (modoEdicion) {
            itemsCotizadosEditar = itemsCotizados
                .filter(
                    x => solicitudActual?.cotizaciones?.find(
                        x => x.oldId === this.state.idCotizacion
                    ).item_cotizacion.some(y => y.item_recurso_id === x.id)
                )
                .map(x => ({
                    ...x,
                    item_recurso_id: x.id,
                    descripcion: values[`descripcion{${x.id}}`] ? values[`descripcion{${x.id}}`] : null,
                    precio: x.precio ? x.precio.replace(/\./g, '').replace(',', '.') : 0
                }));
        } else {
            itemsCotizadosCrear = itemsCotizados.map(x => ({
                ...x,
                item_recurso_id: x.id,
                descripcion: values[`descripcion{${x.id}}`] ? values[`descripcion{${x.id}}`] : null,
                precio: x.precio ? x.precio.replace(/\./g, '').replace(',', '.') : 0
            }));
        }
        if (modoEdicion) {
            if (itemsCotizadosEditar.length <= 18) {
                if (
                    itemsCotizadosEditar.every(x => x.precio !== null) &&
                    itemsCotizadosEditar.every(x => x.descripcion !== null)
                ) {
                    if (totalPesos < 10000000 || (totalPesos >= 10000000 && values.comentario && values.comentario != '')) {
                        await editarCotizaciones(this.state.idCotizacion, {
                            ...values,
                            fecha: moment().toISOString(),
                            impuesto_incluido: values.impuesto_incluido ? values.impuesto_incluido : false,
                            solicitud_recurso_id: idSolicitud,
                            grupo_cotizacion_id: idGrupoCotizacion,
                            creador_id: usuarioAutenticado.data.id,
                            monto_total: total,
                            iva: iva,
                            monto_total_pesos: totalPesos,
                            cambio_moneda: values.cambio_moneda?.replace(/\./g, '').replace(',', '.'),
                            cambioMonedaCLP: cambioMonedaCLP,
                            montoTotalLocal: totalLocal,
                        });
                        Promise.all(
                            itemsCotizadosEditar.map(async x => {
                                return {
                                    item: await editarItemsCotizados(
                                        solicitudActual?.cotizaciones?.find(
                                            x => x.oldId === this.state.idCotizacion
                                        ).item_cotizacion.filter(y => y.item_recurso_id === x.id)[0].id,
                                        { ...x }
                                    )
                                };
                            })
                        );
                        toggleModoEdicion(false);
                        assignMensaje('Su cotización fue Editada con éxito');
                        recargarDatos(false);
                        this.setState({
                            ...this.state,
                            confirmacion: false,
                            openCrearCotizacion: false,
                            open: true,
                            accion: false,
                            idCotizacion: null,
                        });
                    } else {
                        assignMensaje('Para compras mayor a 10MM es obligatorio el campo Comentario para explicar el contexto de la compra.');
                        this.setState({ ...this.state, confirmacion: false, open: true, accion: false });
                    }
                } else {
                    assignMensaje('Todos los items deben tener precio y descripción');
                    this.setState({ ...this.state, confirmacion: false, open: true, accion: false });
                }
            } else {
                assignMensaje('Por ahora solo puede incluir un máximo de 18 ítems en una sola cotización, ' +
                    'debido al espacio disponible en nuestro formato de OC. Puede crear otra cotización para el ' +
                    'resto de ítems.\n\nNº ítems: ' + itemsCotizadosEditar.length
                );
                this.setState({ ...this.state, confirmacion: false, open: true, accion: false });
            }
        } else {
            if (values.archivo) {
                if (itemsCotizadosCrear.length <= 18) {
                    if (
                        itemsCotizadosCrear.every(x => x.precio !== null) &&
                        itemsCotizadosCrear.every(x => x.descripcion !== null)
                    ) {
                        if (totalPesos < 10000000 || (totalPesos >= 10000000 && values.comentario && values.comentario != '')) {
                            this.setState({ loadingCotizacion: true });
                            let archivoAdjunto = await uploadFileAsPromise(values.archivo, "1eDjeNO3dF1pvh8yQ4HCDCMO_nvfVGbGY");
                            let cotizacionActual = await agregarCotizacion({
                                ...values,
                                fecha: moment().toISOString(),
                                impuesto_incluido: values.impuesto_incluido ? values.impuesto_incluido : false,
                                solicitud_recurso_id: idSolicitud,
                                grupo_cotizacion_id: idGrupoCotizacion,
                                creador_id: usuarioAutenticado.data.id,
                                monto_total: total,
                                archivo_id: archivoAdjunto.id,
                                iva: iva,
                                monto_total_pesos: totalPesos,
                                cambio_moneda: values.cambio_moneda?.replace(/\./g, '').replace(',', '.'),
                                cambioMonedaCLP: cambioMonedaCLP,
                                montoTotalLocal: totalLocal,
                                estado_id: 21,
                                todo_recibido: false,
                                es_gasto_reemb: esGastoReemb,
                                es_tarifa_gestion: !esGastoReemb && esTarifaGestion
                            });
                            await agregarItemsCotizados({
                                items: itemsCotizadosCrear.map(x => ({ ...x, cotizacion_id: cotizacionActual.id }))
                            });
                            assignMensaje('Su cotización fue creada con éxito');
                            recargarDatos(false);
                            this.setState({
                                ...this.state,
                                loadingCotizacion: false,
                                confirmacion: false,
                                openCrearCotizacion: false,
                                open: true,
                                accion: false
                            });
                        } else {
                            assignMensaje('Para compras mayor a 10MM es obligatorio el campo Comentario para explicar el contexto de la compra.');
                            this.setState({ ...this.state, confirmacion: false, open: true, accion: false });
                        }
                    } else {
                        assignMensaje('Todos los items deben tener precio y descripción');
                        this.setState({ ...this.state, confirmacion: false, open: true, accion: false });
                    }
                } else {
                    assignMensaje('Por ahora solo puede incluir un máximo de 18 ítems en una sola cotización, ' +
                        'debido al espacio disponible en nuestro formato de OC. Puede crear otra cotización para el ' +
                        'resto de ítems.\n\nNº ítems: ' + itemsCotizadosCrear.length
                    );
                    this.setState({ ...this.state, confirmacion: false, open: true, accion: false });
                }
            } else {
                assignMensaje('Debe adjuntar un archivo de cotización');
                this.setState({ ...this.state, confirmacion: false, open: true, accion: false });
            }
        }
    };

    //Asignar Id del grupo de cotizacion actual
    handleIdGrupoCotizacion = id => e => {
        const { idGrupoCotizacion, assignIdGrupoCotizacion } = this.props;
        if (idGrupoCotizacion === id) {
            assignIdGrupoCotizacion(-1);
        } else {
            assignIdGrupoCotizacion(id);
        }
    };

    //Funcion para eliminar Items de la cotizacion
    handleEliminarItemCotizacion = id => e => {
        const { delItemsCotizacion, itemsCotizados, assignMensaje } = this.props;
        if (itemsCotizados.length > 1) {
            delItemsCotizacion(id);
            //assignMensaje('Item Eliminado')
            //this.setState({...this.state, confirmacion: false, open: true, accion: false })
        } else {
            assignMensaje('Debe cotizar al menos un Item');
            this.setState({ ...this.state, confirmacion: false, open: true, accion: false });
        }
    };

    handleCotizacionModal = (accion, id) => async e => {
        const {
            assignMensaje,
            fetchItemsCotizacion,
            solicitudActual,
            toggleModoEdicion,
        } = this.props;
        switch (accion) {
            case 'aprobar':
                assignMensaje(`¿Esta seguro que desea aprobar la cotización?`);
                this.setState({
                    ...this.state,
                    confirmacion: true,
                    open: true,
                    accion: this.handleAprobarCotizacion(id)
                });
                break;
            case 'rechazar':
                assignMensaje('');
                this.setState({ ...this.state, confirmacion: false, openModalRechazo: true, accion: false, idCotizacion: id, loading: false });
                break;
            case 'borrar':
                assignMensaje(`¿Esta seguro que desea borrar la cotización?`);
                this.setState({
                    ...this.state,
                    confirmacion: true,
                    open: true,
                    accion: this.handleBorrarCotizacion(id)
                });
                break;
            case 'editar':
                toggleModoEdicion(true);
                await fetchItemsCotizacion(solicitudActual);
                this.setState({ ...this.state, confirmacion: false, openCrearCotizacion: true, accion: false, idCotizacion: id });
                break;
            case 'ver':
                this.setState({ confirmacion: false, openVerCotizacion: true, accion: false, idCotizacion: id, recepcionRealizada: false });
                break;
            default:
                break;
        }
    };

    handleBorrarCotizacion = id => async e => {
        const { editarCotizaciones, recargarDatos, assignMensaje } = this.props;
        await editarCotizaciones(id, { activo: false });
        recargarDatos(false);
        assignMensaje('Cotización Borrada');
        this.setState({ ...this.state, open: true, confirmacion: false, accion: false, idCotizacion: null });
    };

    handleAprobarCotizacion = id => async e => {
        const {
            resetItemsCotizados,
            assignMensaje,
            aprobarCotizacion,
            editarCotizaciones,
            solicitarAprobacionCotizacion,
            usuarioAutenticado,
            editarItems,
            aprobador2,
            solicitudActual,
            proveedores,
            recargarDatos,
            rechazarCotizacion,
        } = this.props;
        this.setState({ confirmacion: false, open: true, accion: false, loading: true });
        const itemsCotizacion = solicitudActual.cotizaciones.find(x => x.id === id).item_cotizacion;
        const itemsCotizados = solicitudActual.items.filter(x => itemsCotizacion.some(y => y.item_recurso_id === x.id));
        if (!itemsCotizados.some(x => x.estado !== 22)) {
            try {
                let cotizacionActual = solicitudActual.cotizaciones.find(x => x.id === id);
                let totalCotizacion =
                    cotizacionActual.tipo_moneda == 'CLP'
                        ? cotizacionActual.monto_total
                        : cotizacionActual.monto_total_pesos;
                let crearOC = false;
                let centroFirmaId = this.state.centrosAll.find(a => a.id === cotizacionActual.ubicacion_logistica_id).usuario_firma_oc_id;
                let esAprobador1 = aprobador2;
                let esAprobador2 = solicitudActual.gerenteId === usuarioAutenticado.data.id;

                if (cotizacionActual.es_gasto_reemb) {
                    //Es compra Gasto Reembolsable
                    if (cotizacionActual.revisa_centro_id) {
                        //Ya está aprobada por Compras
                        if (totalCotizacion < 5000000) {
                            //Está aprobando GAF y genera OC
                            crearOC = true;
                            await editarCotizaciones(id, {
                                estado_id: 23,
                                fecha_orden: moment().toISOString(),
                                revisa_gaf_id: usuarioAutenticado.data.id,
                                fecha_revisa_gaf: moment().toISOString()
                            });
                        } else if (cotizacionActual.aprobador_id) {
                            //Está aprobando GAF y genera OC
                            crearOC = true;
                            await editarCotizaciones(id, {
                                estado_id: 23,
                                fecha_orden: moment().toISOString(),
                                revisa_gaf_id: usuarioAutenticado.data.id,
                                fecha_revisa_gaf: moment().toISOString()
                            });
                        } else {
                            //Está aprobando Gerente
                            let dataEditar = {
                                aprobador_id: usuarioAutenticado.data.id,
                                fecha_aprobacion: moment().toISOString()
                            };
                            // if (usuarioAutenticado.data.id == "5e0f291a70edaf00137fd297") {
                            if (usuarioAutenticado.data.id == APROBADOR_GERENTE_GAF) {
                                //Si el usuario es GAF, se guarda su aprobación y genera OC.
                                crearOC = true;
                                dataEditar.estado_id = 23;
                                dataEditar.fecha_orden = moment().toISOString();
                                dataEditar.revisa_gaf_id = usuarioAutenticado.data.id;
                                dataEditar.fecha_revisa_gaf = moment().toISOString();
                                await editarCotizaciones(id, dataEditar);
                            } else {
                                await editarCotizaciones(id, dataEditar);
                                await solicitarAprobacionCotizacion(id);
                                assignMensaje('Cotización aprobada y enviada al siguiente aprobador.');
                                resetItemsCotizados();
                                this.setState({
                                    confirmacion: false,
                                    open: true,
                                    accion: false,
                                    loading: false
                                });
                            }
                        }
                    } else {
                        //Está aprobando Compras
                        let dataEditar = {
                            revisa_centro_id: usuarioAutenticado.data.id,
                            fecha_revisa_centro: moment().toISOString()
                        };
                        // if (totalCotizacion < 5000000 && usuarioAutenticado.data.id == "5e0f291a70edaf00137fd297") {
                        if (totalCotizacion < 5000000 && usuarioAutenticado.data.id == APROBADOR_GERENTE_GAF) {
                            //Si es menor a 5MM y usuario es GAF, se guarda su aprobación y genera OC.
                            crearOC = true;
                            dataEditar.estado_id = 23;
                            dataEditar.fecha_orden = moment().toISOString();
                            dataEditar.revisa_gaf_id = usuarioAutenticado.data.id;
                            dataEditar.fecha_revisa_gaf = moment().toISOString();
                            await editarCotizaciones(id, dataEditar);
                        } else if (totalCotizacion >= 5000000 && esAprobador2) {
                            //Si es mayor a 5MM y usuario es Gerente, se guarda su aprobación.
                            dataEditar.aprobador_id = usuarioAutenticado.data.id;
                            dataEditar.fecha_aprobacion = moment().toISOString();
                            // if (usuarioAutenticado.data.id == "5e0f291a70edaf00137fd297") {
                            if (usuarioAutenticado.data.id == APROBADOR_GERENTE_GAF){
                                //Si el usuario es GAF, se guarda su aprobación y genera OC.
                                crearOC = true;
                                dataEditar.estado_id = 23;
                                dataEditar.fecha_orden = moment().toISOString();
                                dataEditar.revisa_gaf_id = usuarioAutenticado.data.id;
                                dataEditar.fecha_revisa_gaf = moment().toISOString();
                                await editarCotizaciones(id, dataEditar);
                            } else {
                                await editarCotizaciones(id, dataEditar);
                                await solicitarAprobacionCotizacion(id);
                                assignMensaje('Cotización aprobada y enviada al siguiente aprobador.');
                                resetItemsCotizados();
                                this.setState({
                                    confirmacion: false,
                                    open: true,
                                    accion: false,
                                    loading: false
                                });
                            }
                        } else {
                            await editarCotizaciones(id, dataEditar);
                            await solicitarAprobacionCotizacion(id);
                            assignMensaje('Cotización aprobada y enviada al siguiente aprobador.');
                            resetItemsCotizados();
                            this.setState({
                                confirmacion: false,
                                open: true,
                                accion: false,
                                loading: false
                            });
                        }
                    }
                } else if (cotizacionActual.es_tarifa_gestion) {
                    //Es compra Tarifa de Gestión
                    if (cotizacionActual.revisa_centro_id) {
                        //Ya está aprobada por Compras, está aprobando GAF y genera OC
                        crearOC = true;
                        await editarCotizaciones(id, {
                            estado_id: 23,
                            fecha_orden: moment().toISOString(),
                            revisa_gaf_id: usuarioAutenticado.data.id,
                            fecha_revisa_gaf: moment().toISOString()
                        });
                    } else {
                        //Está aprobando Compras
                        let dataEditar = {
                            revisa_centro_id: usuarioAutenticado.data.id,
                            fecha_revisa_centro: moment().toISOString()
                        };
                        if (totalCotizacion < 10000000) {
                            //Si es menor a 10MM genera OC.
                            crearOC = true;
                            dataEditar.estado_id = 23;
                            dataEditar.fecha_orden = moment().toISOString();
                            await editarCotizaciones(id, dataEditar);
                        // } else if (usuarioAutenticado.data.id == "5e0f291a70edaf00137fd297") {
                        } else if (usuarioAutenticado.data.id == APROBADOR_GERENTE_GAF) {
                            //Si el usuario es GAF, se guarda su aprobación y genera OC.
                            crearOC = true;
                            dataEditar.estado_id = 23;
                            dataEditar.fecha_orden = moment().toISOString();
                            dataEditar.revisa_gaf_id = usuarioAutenticado.data.id;
                            dataEditar.fecha_revisa_gaf = moment().toISOString();
                            await editarCotizaciones(id, dataEditar);
                        } else {
                            await editarCotizaciones(id, dataEditar);
                            await solicitarAprobacionCotizacion(id);
                            assignMensaje('Cotización aprobada y enviada al siguiente aprobador.');
                            resetItemsCotizados();
                            this.setState({
                                confirmacion: false,
                                open: true,
                                accion: false,
                                loading: false
                            });
                        }
                    }
                } else {
                    //Es compra Otros
                    if (cotizacionActual.revisa_centro_id) {
                        //Ya está aprobada por Compras
                        if (totalCotizacion < 5000000) {
                            //Está aprobando Director y genera OC
                            crearOC = true;
                            await editarCotizaciones(id, {
                                estado_id: 23,
                                fecha_orden: moment().toISOString(),
                                aprobador_id: usuarioAutenticado.data.id,
                                fecha_aprobacion: moment().toISOString()
                            });
                        } else if (cotizacionActual.aprobador_id) {
                            //Está aprobando GAF y genera OC
                            crearOC = true;
                            await editarCotizaciones(id, {
                                estado_id: 23,
                                fecha_orden: moment().toISOString(),
                                revisa_gaf_id: usuarioAutenticado.data.id,
                                fecha_revisa_gaf: moment().toISOString()
                            });
                        } else {
                            //Está aprobando Gerente
                            let dataEditar = {
                                aprobador_id: usuarioAutenticado.data.id,
                                fecha_aprobacion: moment().toISOString()
                            };
                            if (totalCotizacion < 10000000) {
                                //Si es menor a 10MM genera OC
                                crearOC = true;
                                dataEditar.estado_id = 23;
                                dataEditar.fecha_orden = moment().toISOString();
                                await editarCotizaciones(id, dataEditar);
                            // } else if (usuarioAutenticado.data.id == "5e0f291a70edaf00137fd297") {
                            } else if (usuarioAutenticado.data.id == APROBADOR_GERENTE_GAF) {
                                //Si usuario es GAF, se guarda su aprobación y genera OC
                                crearOC = true;
                                dataEditar.estado_id = 23;
                                dataEditar.fecha_orden = moment().toISOString();
                                dataEditar.revisa_gaf_id = usuarioAutenticado.data.id;
                                dataEditar.fecha_revisa_gaf = moment().toISOString();
                                await editarCotizaciones(id, dataEditar);
                            } else {
                                await editarCotizaciones(id, dataEditar);
                                await solicitarAprobacionCotizacion(id);
                                assignMensaje('Cotización aprobada y enviada al siguiente aprobador.');
                                resetItemsCotizados();
                                this.setState({
                                    confirmacion: false,
                                    open: true,
                                    accion: false,
                                    loading: false
                                });
                            }
                        }
                    } else {
                        //Está aprobando Compras
                        let dataEditar = {
                            revisa_centro_id: usuarioAutenticado.data.id,
                            fecha_revisa_centro: moment().toISOString()
                        };
                        if (totalCotizacion < 1000000) {
                            //Si es menor a 1MM genera OC
                            crearOC = true;
                            centroFirmaId = usuarioAutenticado.data.id;
                            dataEditar.estado_id = 23;
                            dataEditar.fecha_orden = moment().toISOString();
                            await editarCotizaciones(id, dataEditar);
                        } else if (totalCotizacion < 5000000) {
                            if (esAprobador1) {
                                //Si es entre 1MM-5MM y usuario es Director
                                crearOC = true;
                                dataEditar.estado_id = 23;
                                dataEditar.fecha_orden = moment().toISOString();
                                dataEditar.aprobador_id = usuarioAutenticado.data.id;
                                dataEditar.fecha_aprobacion = moment().toISOString();
                                await editarCotizaciones(id, dataEditar);
                            } else {
                                await editarCotizaciones(id, dataEditar);
                                await solicitarAprobacionCotizacion(id);
                                assignMensaje('Cotización aprobada y enviada al siguiente aprobador.');
                                resetItemsCotizados();
                                this.setState({
                                    confirmacion: false,
                                    open: true,
                                    accion: false,
                                    loading: false
                                });
                            }
                        } else if (esAprobador2) {
                            //Si usuario es Director se guarda su aprobación
                            dataEditar.aprobador_id = usuarioAutenticado.data.id;
                            dataEditar.fecha_aprobacion = moment().toISOString();
                            if (totalCotizacion < 10000000) {
                                //Si es menor a 10MM genera OC
                                crearOC = true;
                                dataEditar.estado_id = 23;
                                dataEditar.fecha_orden = moment().toISOString();
                                await editarCotizaciones(id, dataEditar);
                            // } else if (usuarioAutenticado.data.id == "5e0f291a70edaf00137fd297") {
                            } else if (usuarioAutenticado.data.id == APROBADOR_GERENTE_GAF) {
                                //Si usuario es GAF, se guarda su aprobación y genera OC
                                crearOC = true;
                                dataEditar.estado_id = 23;
                                dataEditar.fecha_orden = moment().toISOString();
                                dataEditar.revisa_gaf_id = usuarioAutenticado.data.id;
                                dataEditar.fecha_revisa_gaf = moment().toISOString();
                                await editarCotizaciones(id, dataEditar);
                            } else {
                                await editarCotizaciones(id, dataEditar);
                                await solicitarAprobacionCotizacion(id);
                                assignMensaje('Cotización aprobada y enviada al siguiente aprobador.');
                                resetItemsCotizados();
                                this.setState({
                                    confirmacion: false,
                                    open: true,
                                    accion: false,
                                    loading: false
                                });
                            }
                        } else {
                            await editarCotizaciones(id, dataEditar);
                            await solicitarAprobacionCotizacion(id);
                            assignMensaje('Cotización aprobada y enviada al siguiente aprobador.');
                            resetItemsCotizados();
                            this.setState({
                                confirmacion: false,
                                open: true,
                                accion: false,
                                loading: false
                            });
                        }
                    }
                }
                
                if (crearOC) {
                    let dataOC = await aprobarCotizacion(
                        id,
                        { ...cotizacionActual, estado_id: 23 },
                        centroFirmaId
                    );
                    await Promise.all(
                        itemsCotizados.map(async y => {
                            return {
                                items: await editarItems(y.id, {
                                    ...y,
                                    fecha_requerida: moment(y.fecha_requerida).toISOString(),
                                    estado: 12
                                })
                            };
                        })
                    );

                    let cotizacionesRestantes = solicitudActual.cotizaciones.filter(x => x.id !== id && x.estado_id === 22);
                    // Si algunas de las otras cotizaciones pendientes tienen algún ítem que se aprobó en esta cotización, se rechaza esa cotización.
                    for (let i = 0; i < cotizacionesRestantes.length; i++) {
                        if (cotizacionesRestantes[i].item_cotizacion.some(x => itemsCotizados.some(y => y.id === x.item_recurso_id))) {
                            await rechazarCotizacion(cotizacionesRestantes[i].id, {
                                ...cotizacionesRestantes[i],
                                estado_id: 24,
                                comentario: `Rechazada automáticamente por aprobación de ${cotizacionActual.codigo}`,
                                cotizacion_id: cotizacionesRestantes[i].id,
                                fecha: moment().toISOString(),
                                solicitud_recurso_id: solicitudActual.id,
                                usuario_id: usuarioAutenticado.data.id,
                                aprobada: 0
                            });
                        }
                    }
                    
                    if (['Anticipo', 'Pago Online'].indexOf(cotizacionActual.condicion_pago) > -1) {
                        // Enviar a Gestor de Pagos como pago directo
                        let montoNeto = totalCotizacion ? parseInt(totalCotizacion) : 0;
                        let montoIva = cotizacionActual.iva
                          ? cotizacionActual.tipo_moneda == 'CLP' || cotizacionActual.tipo_moneda == 'PEN'
                            ? parseInt(cotizacionActual.iva)
                            : parseInt(parseFloat(cotizacionActual.iva) * parseFloat(cotizacionActual.cambio_moneda))
                          : 0;
                        if (cotizacionActual.iva) montoNeto = montoNeto - montoIva;
                        let provCot= proveedores.find(x => x.id === cotizacionActual.proveedor_id);
                        let empresa = await leer(`/empresa-cyd/${cotizacionActual.empresa_cyd_id}`);

                        let datosPago = {
                            estado: "Pendiente de Pago",
                            tipo_rendicion: "Pago inmediato",
                            numero_factura: "",
                            monto_total: totalCotizacion ? parseInt(totalCotizacion) : 0,
                            monto_iva: montoIva,
                            monto_neto: montoNeto,
                            monto_total_dolares: 0,
                            clasificacion_factura: "",
                            es_exenta: montoIva == 0,
                            descripcion: 'Pago inmediato Orden de compra ' + dataOC.codigo +' gestionada a través del SDA.',
                            comentario: "",
                            dias_vencimiento: 0,
                            fecha_factura: null,
                            fecha_vencimiento: moment().format(),
                            _contrato_revision_ref: null,
                            _gerencia_revision_ref: null,
                            _sociedad_ref: null,
                            contrato: solicitudActual.contrato._id,
                            // contrato: "5f7e0fb7952c2d0019e8721f",
                            gerencia: null,
                            sociedad: formatoRut(empresa.rut.replace('-', '')),
                            proveedor: {
                                nombre: provCot.nombre,
                                rut: provCot.rut,
                                correo_transferencia: provCot.banco_email,
                                numero_cuenta: provCot.banco_numero_cuenta,
                                tipo_cuenta: provCot.banco_tipo_cuenta,
                                banco: provCot.banco_nombre
                            },
                            codigo_orden_compra: dataOC.codigo,
                            codigo_solicitud_recurso: solicitudActual.codigo,
                            id_orden_compra: dataOC.id,
                            id_usuario_aprobador_sda: usuarioAutenticado.data.id,
                            aprobador_sda: usuarioAutenticado.data.nombres,
                            archivo_factura: null,
                            id_archivo_oc: dataOC.archivo_id,
                            id_archivo_respaldo: cotizacionActual.archivo_id,
                            condicion_pago: cotizacionActual.condicion_pago,
                            creador_id: cotizacionActual.creador_id
                        }

                        console.log("datos pago: ", datosPago);
                        let token = await getCurrentUser();
                        // let response = await fetch('http://localhost:8000/facturas/pago-directo', {
                        let response = await fetch('https://api-rendiciones.cydingenieria.com/facturas/pago-directo', {
                            method: 'POST',
                            headers: {
                                Accept: 'application/json',
                                'Content-Type': 'application/json',
                                Authorization: 'Bearer ' + token.token
                            },
                            body: JSON.stringify(datosPago)
                        });
                        const jsonResponse = await response.json();
                        if (response.status !== 200 && response.status !== 201 && response.status !== 204) {
                            let error;
                            if (jsonResponse && jsonResponse.errors) {
                                error = jsonResponse.errors;
                            }
                            console.log("errores: ", error);
                            Sentry.captureMessage(`Error envío Pago Inmediato, oc: ${dataOC.codigo}`);
                        }
                        console.log("resultado pago directo: ", jsonResponse);
                    }
                    assignMensaje(`Cotización aprobada`);
                    this.setState({ ...this.state, confirmacion: false, open: true, accion: false, loading: false, idCotizacion: null });
                }
                recargarDatos(false);
            } catch (e) {
                recargarDatos(false);
                console.log(e);
                assignMensaje(`Ha ocurrido un error, vuelva a intentarlo`);
                resetItemsCotizados();
                this.setState({ ...this.state, confirmacion: false, open: true, accion: false, loading: false });
            }
        } else if (itemsCotizados.some(x => x.estado === 8)) {
            assignMensaje(`Debe esperar que el responsable solicite la aprobación`);
            resetItemsCotizados();
            this.setState({ ...this.state, confirmacion: false, open: true, accion: false, loading: false });
        } else {
            assignMensaje(`Items ya fueron aprobados en otra cotización`);
            resetItemsCotizados();
            this.setState({ ...this.state, confirmacion: false, open: true, accion: false, loading: false });
        }
    };

    handleRechazarCotizacion = async values => {
        const {
            assignMensaje,
            solicitudActual,
            rechazarCotizacion,
            usuarioAutenticado,
            idSolicitud,
            recargarDatos,
            editarItems
        } = this.props;
        let id = this.state.idCotizacion;
        this.setState({ loading: true });
        const itemsCotizacion = solicitudActual.cotizaciones.find(x => x.id === id).item_cotizacion;
        const itemsCotizados = solicitudActual.items.filter(x => itemsCotizacion.some(y => y.item_recurso_id === x.id));
        let cotizacionActual = solicitudActual.cotizaciones.find(x => x.id === id);
        await rechazarCotizacion(id, {
            ...cotizacionActual,
            estado_id: 24,
            comentario: values.comentario,
            cotizacion_id: id,
            fecha: moment().toISOString(),
            solicitud_recurso_id: idSolicitud,
            usuario_id: usuarioAutenticado.data.id,
            aprobada: 0
        });
        
        let cotizacionesRestantes = solicitudActual.cotizaciones.filter(x => x.id !== id && x.estado_id === 22);
        let itemsRestantesPendientes = [];
        for (let i = 0; i < cotizacionesRestantes.length; i++) {
            let itemsRestantes = solicitudActual.cotizaciones.find(x => x.id === cotizacionesRestantes[i].id).item_cotizacion;
            itemsRestantesPendientes = itemsRestantesPendientes.concat(itemsRestantes);
        }

        await Promise.all(
            itemsCotizados.map(async y => {
                if (y.estado === 22 && !itemsRestantesPendientes.some(x => x.item_recurso_id === y.id)) {
                    return {
                        items: await editarItems(y.id, {
                            ...y,
                            fecha_requerida: moment(y.fecha_requerida).toISOString(),
                            estado: 8
                        })
                    };
                }
            })
        );
        assignMensaje(`Cotización rechazada`);
        recargarDatos(false);
        this.setState({ ...this.state, confirmacion: false, open: true, accion: false, idCotizacion: null, openModalRechazo: false, loading: false });
    };

    handleVerDocumento = (idArchivo, nombre = 'Cotizacion') => {
        var request =
            'https://www.googleapis.com/drive/v3/files/' +
            idArchivo +
            '?alt=media';
        var newTab = window.open(window.location.href + '/waiting', '_blank');
        downloadUrlAsPromise(request, nombre)
            .then(function (response) {
                var tipoGrupo = response.blob.type ? response.blob.type.split('/')[0] : '';
                if (tipoGrupo == 'image' || response.blob.type == 'application/pdf') {
                    var blobUrl = URL.createObjectURL(response.blob);

                    newTab.location = blobUrl;
                } else {
                    newTab.close();
                    saveAs(response.blob, nombre);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    handleOnChangeFileComprobante = e => {
        this.setState({ ...this.state, comprobante: e.target.files[0] ? e.target.files[0].name : '' });
    };

    handleOnChangeFileFactura = e => {
        this.setState({ ...this.state, nomArchivoFactura: e.target.files[0] ? e.target.files[0].name : '' });
    };

    handleEditarItemsModal = (id, accion) => async e => {
        const {
            assignMensaje,
            fetchItems,
            assignIditem,
            idSolicitud,
            solicitudActual,
        } = this.props;
        let cantidadAnterior = solicitudActual.items.find(x => x.id === id).cantidad;
        switch (accion) {
            case 'separar': {
                this.setState({ ...this.state, open: true, confirmacion: false, accion: false }, async () => {
                    assignMensaje('Cargando');
                    let itemActual = solicitudActual.items.find(x => x.id === id);
                    if (itemActual.cantidad === cantidadAnterior) {
                        await assignIditem(id);
                        this.setState({ ...this.state, confirmacion: false, openModalSeparar: true, accion: false });
                    } else {
                        await fetchItems(idSolicitud);
                        assignMensaje('Lista de Items actualizada');
                        this.setState({ ...this.state, confirmacion: false, open: true, accion: false });
                    }
                });
                break;
            }
            default:
                break;
        }
    };

    handleRecepcionarTodoModal = (idCot, itemsARecibir) => {
        const { assignMensaje, solicitudActual } = this.props;
        if (itemsARecibir.length > 0) {
            let todoRecibido = true;
            let clasesNom = [];
            if (solicitudActual.cotizaciones.find(x => x.oldId === this.state.idCotizacion).plazo_entrega == null) {
                todoRecibido = false;
                solicitudActual.cotizaciones.find(x => x.oldId === this.state.idCotizacion).item_cotizacion.forEach(x => {
                    let itemSol = solicitudActual.items.find(y => y.id === x.item_recurso_id);
                    if (clasesNom.indexOf(itemSol.claseRef.nombre) === -1) clasesNom.push(itemSol.claseRef.nombre);
                });
            } else {
                solicitudActual.cotizaciones.find(x => x.oldId === this.state.idCotizacion).item_cotizacion.forEach(x => {
                    let itemSol = solicitudActual.items.find(y => y.id === x.item_recurso_id);
                    if (itemSol && itemSol.estado === 12 && itemsARecibir.filter(y => y.id === x.id).length === 0)
                        todoRecibido = false;

                    if (clasesNom.indexOf(itemSol.claseRef.nombre) == -1) clasesNom.push(itemSol.claseRef.nombre);
                });
            }
            this.setState({
                ...this.state,
                confirmacion: false,
                openItemRecibido: true,
                descripcionFactura: 'Compra de ' + clasesNom.join(', '),
                loadingItemRecibido: false,
                accion: false,
                itemsARecepcionar: [...itemsARecibir],
                todoRecibido: todoRecibido,
                dataCotizacionRecepcion: solicitudActual.cotizaciones.filter(x => x.id === idCot)[0]
            });
        } else {
            assignMensaje('No ha seleccionado ningún ítem para recepcionar.');
            this.setState({
                ...this.state,
                confirmacion: false,
                open: true,
                accion: false,
                itemsARecepcionar: [],
                todoRecibido: false
            });
        }
    }
    verificarArchivoFactura = values => {
        const { assignMensaje } = this.props;
        let varThis = this;
        if ((values.tipo_doc === "Factura" || values.orden_compra) && values.estado_pago == 'Procesando Pago') {
            if (values.file.type !== "application/pdf") {
                assignMensaje('El archivo factura debe ser tipo PDF.');
                this.setState({ ...this.state, confirmacion: false, open: true, accion: false });
            } else {
                const reader = new FileReader();
                reader.readAsArrayBuffer(values.file);
                reader.onload = function () {
                    var files = new Blob([reader.result], { type: 'application/pdf' });
                    files.text().then(x => {
                        console.log("isEncrypted", x.includes("Encrypt"))
                        console.log("isEncrypted", x.substring(x.lastIndexOf("<<"), x.lastIndexOf(">>")).includes("/Encrypt"));
                        console.log(values.file.name);

                        if (x.includes("Encrypt")) {
                            assignMensaje('El archivo pdf seleccionado se encuentra encriptado/protegido. Para desencriptar el archivo, puede imprimir el documento como archivo pdf para generar una copia sin proteger.');
                            varThis.setState({ confirmacion: false, open: true, accion: false, mostrarAyudaPDFProtegido: true });
                        } else {
                            if (varThis.state.openItemRecibido) {
                                // console.log("a recepcionar todo después de verificar");
                                varThis.handleRecepcionarTodo(values);
                            } else {
                                // console.log("a cargar factura después de verificar");
                                varThis.handleCargarFactura(values);
                            }
                        }
                    });
                }
            }
        } else {
            if (this.state.openItemRecibido) {
                // console.log("a recepcionar todo");
                this.handleRecepcionarTodo(values);
            } else {
                // console.log("a cargar factura");
                this.handleCargarFactura(values);
            }
        }
    }

    handleRecepcionarTodo = async values => {
        const {
            idSolicitud,
            assignMensaje,
            solicitudActual,
            agregarGuiaDespacho,
            agregarFactura,
            agregarRecepcionItemRecurso,
            usuarioAutenticado,
            proveedores,
            recargarDatos,
        } = this.props;
        // console.log("recepcionando ítems", values, this.state.itemsARecepcionar);
        if (this.state.itemsARecepcionar.length > 0) {
            try {
                this.setState({ loadingItemRecibido: true, recepcionRealizada: false });
                let cotizacion = solicitudActual.cotizaciones.find(x => x.id === this.state.itemsARecepcionar[0].cotizacion_id);
                let provCot= proveedores.filter(x => x.id === cotizacion.proveedor_id)[0];
                let datosProvFaltantes = '';
                if (provCot.nombre == null || provCot.nombre == "") datosProvFaltantes += '- Nombre \n';
                if (provCot.rut == null || provCot.rut == "") datosProvFaltantes += '- RUT \n';
                if (provCot.banco_nombre == null || provCot.banco_nombre == "") datosProvFaltantes += '- Nombre Banco \n';
                if (provCot.banco_tipo_cuenta == null || provCot.banco_tipo_cuenta == "") datosProvFaltantes += '- Tipo de Cuenta \n';
                if (provCot.banco_numero_cuenta == null || provCot.banco_numero_cuenta == "") datosProvFaltantes += '- Nº de Cuenta \n';
                if (provCot.banco_email == null || provCot.banco_email == "") datosProvFaltantes += '- Correo electrónico (bancario) \n';

                let token = await getCurrentUser();
                let facRend = await fetch('https://api-rendiciones.cydingenieria.com/facturas/?numero_factura_eq=' + values.numero + '&proveedor.rut_eq=' + provCot.rut + '&eliminado_eq=false', {
                    method: 'GET',
                    headers: {
                        Authorization: 'Bearer ' + token.token
                    }
                });
                let dataFacRend = await facRend.json();

                if (values.tipo_doc === 'Factura' && values.estado_pago == 'Procesando Pago' && dataFacRend?.data && dataFacRend.data.length > 0) {
                    let mensajeFac = 'No se puede realizar la recepción y enviar a pago porque en el Sistema Gestor de ';
                    mensajeFac += 'Pagos ya existe una factura con el número "' + values.numero + '" para el proveedor "';
                    mensajeFac += provCot.nombre + '".';
                    assignMensaje(mensajeFac);
                    this.setState({
                        openItemRecibido: false,
                        // loadingItemRecibido: false,
                        modal: true,
                        confirmacion: false,
                        open: true,
                        accion: false
                    });
                } else {
                    if (datosProvFaltantes != '' && values.tipo_doc === 'Factura' && values.estado_pago == 'Procesando Pago') {
                        let mensajeProv = 'No se puede realizar la recepción y enviar a pago por datos faltantes del proveedor.';
                        mensajeProv += ' Complete los siguientes datos faltantes en Gestión de Proveedores.\n\n';
                        mensajeProv += datosProvFaltantes
                        assignMensaje(mensajeProv);
                        this.setState({
                            openItemRecibido: false,
                            // loadingItemRecibido: false,
                            modal: true,
                            confirmacion: false,
                            open: true,
                            accion: false
                        });
                    } else {
                        let fecha = values.fecha.split('/');
                        const formComprobante = {
                            numero: values.numero,
                            fecha: fecha[2] + fecha[1] + fecha[0],
                            orden_compra_id: cotizacion.ordenCompra.id,
                            monto: values.monto ? values.monto.replace(/\./g, '') : 0
                        };

                        let comprobante;
                        let guia_despacho_id = null;
                        let factura_id = null;
                        if (values.tipo_doc === 'Guía Despacho') {
                            let respuesta = await uploadFileAsPromise(values.file, "1u0rJ1Dk8qKaJgOS3_EkOxDh_8X788FXI");
                            comprobante = await agregarGuiaDespacho({
                                ...formComprobante,
                                file_id: respuesta.id,
                                file_nombre: respuesta.nombre,
                                file_url: respuesta.url
                            });
                            guia_despacho_id = comprobante.id;
                        } else {
                            let respuesta = await uploadFileAsPromise(values.file, "1nXCYyLE4rEIB50q98yOBCaVc8e7Nikjx");
                            let fechaDoc = values.fecha_doc.split('/');
                            let momentFechaDoc = moment(fechaDoc[2] + '-' + fechaDoc[1] + '-' + fechaDoc[0] + " 00:00:00", "YYYY-MM-DD HH:mm:ss");
                            let fechaDoc2 = momentFechaDoc.format();
                            momentFechaDoc.add(values.dias_vencimiento, 'd');
                            let fechaVenc = momentFechaDoc.format();
                            
                            if (values.estado_pago == 'Procesando Pago') {
                                let ruta = "facturas/" + provCot.nombre + "/" + values.numero;
                                let dataArchivoStorage = await uploadFileToCloudStorage(values.file, ruta, values.file.name, "informa-pago-facturas");

                                let montoNeto = values.monto ? parseInt(values.monto.replace(/\./g, '')) : 0;
                                if (values.iva) montoNeto = montoNeto - parseInt(values.iva.replace(/\./g, ''));
                                // console.log("enviando a pago coti: ", cotizacion);
                                
                                let userAprobador = cotizacion.aprobadorGaf
                                    ? cotizacion.aprobadorGaf
                                    : cotizacion.aprobador
                                    ? cotizacion.aprobador
                                    : cotizacion.aprobadorCompras;
                                let empresa = await leer(`/empresa-cyd/${cotizacion.empresa_cyd_id}`);
                                let datosPago = {
                                    estado: values.estado_pago,
                                    tipo_rendicion: "Pago directo a proveedor (Orden giro)",
                                    numero_factura: values.numero,
                                    monto_total: values.monto ? parseInt(values.monto.replace(/\./g, '')) : 0,
                                    monto_iva: values.iva ? parseInt(values.iva.replace(/\./g, '')) : 0,
                                    monto_neto: montoNeto,
                                    monto_total_dolares: 0,
                                    clasificacion_factura: values.clasificacion_factura.nombre,
                                    es_exenta: values.iva == '0',
                                    descripcion: values.descripcion,
                                    comentario: values.comentario,
                                    dias_vencimiento: values.dias_vencimiento,
                                    fecha_factura: fechaDoc2,
                                    fecha_vencimiento: fechaVenc,
                                    _contrato_revision_ref: null,
                                    _gerencia_revision_ref: null,
                                    _sociedad_ref: null,
                                    contrato: solicitudActual.contrato._id,
                                    gerencia: null,
                                    sociedad: formatoRut(empresa.rut.replace('-', '')),
                                    proveedor: {
                                        nombre: provCot.nombre,
                                        rut: provCot.rut,
                                        correo_transferencia: provCot.banco_email,
                                        numero_cuenta: provCot.banco_numero_cuenta,
                                        tipo_cuenta: provCot.banco_tipo_cuenta,
                                        banco: provCot.banco_nombre
                                    },
                                    codigo_orden_compra: cotizacion.ordenCompra.codigo,
                                    codigo_solicitud_recurso: solicitudActual.codigo,
                                    id_orden_compra: cotizacion.ordenCompra.id,
                                    id_usuario_aprobador_sda: userAprobador.id,
                                    aprobador_sda: userAprobador.nombres + ' ' + userAprobador.apellidos,
                                    archivo_factura: dataArchivoStorage
                                }
                                console.log("datos pago:", datosPago);
                                let token = await getCurrentUser();
                                let response = await fetch('https://api-rendiciones.cydingenieria.com/facturas', {
                                    method: 'POST',
                                    headers: {
                                        Accept: 'application/json',
                                        'Content-Type': 'application/json',
                                        Authorization: 'Bearer ' + token.token
                                    },
                                    body: JSON.stringify(datosPago)
                                });
                                const jsonResponse = await response.json();
                                if (response.status !== 200 && response.status !== 201 && response.status !== 204) {
                                    let error;
                                    if (jsonResponse && jsonResponse.errors) {
                                        error = jsonResponse.errors;
                                    }
                                    console.log("errores: ", error);
                                }
                                console.log("resultado: ", jsonResponse);
                                token = await getCurrentUser();
                                let facRend = await fetch('https://api-rendiciones.cydingenieria.com/facturas/?numero_factura_eq=' + values.numero + '&proveedor.rut_eq=' + provCot.rut + '&eliminado_eq=false', {
                                    method: 'GET',
                                    headers: {
                                        Authorization: 'Bearer ' + token.token
                                    }
                                });
                                let dataFacRend = await facRend.json();
                                if (!dataFacRend?.data || dataFacRend.data.length == 0) {
                                    Sentry.captureMessage(`Error factura no creada en Gestor de Pagos, N: ${values.numero}, ProvRut: ${provCot.rut}`);
                                    throw new Error("Factura no creada en Gestor de Pagos.");
                                }
                            }
                            comprobante = await agregarFactura({
                                ...formComprobante,
                                file_id: respuesta.id,
                                file_nombre: respuesta.nombre,
                                file_url: respuesta.url,
                                fecha_factura: fechaDoc[2] + fechaDoc[1] + fechaDoc[0],
                                fecha_vencimiento: fechaVenc[2] + fechaVenc[1] + fechaVenc[0],
                                creador_id: usuarioAutenticado.data.id,
                                enviado_a_pago: values.estado_pago == 'Procesando Pago'
                            });
                            factura_id = comprobante.id;
                        }

                        const formRecepcion = {
                            guia_despacho_id: guia_despacho_id,
                            factura_id: factura_id,
                            cantidad: 1,
                            fecha: fecha[2] + '-' + fecha[1] + '-' + fecha[0],
                            // item_cotizacion_id: itemCotizacion.id,
                            items_cotizacion_recibidos: [...this.state.itemsARecepcionar],
                            usuario_id: usuarioAutenticado.data.id,
                            id_solicitud: idSolicitud,
                            todo_recibido: this.state.todoRecibido
                        };

                        if (this.state.todoRecibido) {
                            formRecepcion.cotizacion_id = cotizacion.id;
                            formRecepcion.proveedor_id = cotizacion.proveedor_id;
                            formRecepcion.calidad1 = values.calidad1 === "1" ? true : values.calidad1 === "0" ? false : null;
                            formRecepcion.calidad2 = values.calidad2 === "1" ? true : values.calidad2 === "0" ? false : null;;
                            formRecepcion.calidad3 = values.calidad3 === "1" ? true : values.calidad3 === "0" ? false : null;;
                            formRecepcion.calidad4 = values.calidad4 === "1" ? true : values.calidad4 === "0" ? false : null;;
                            formRecepcion.calidad5 = values.calidad5 === "1" ? true : values.calidad5 === "0" ? false : null;;
                            formRecepcion.calidad6 = values.calidad6 === "1" ? true : values.calidad6 === "0" ? false : null;;
                            formRecepcion.calidad7 = values.calidad7 === "1" ? true : values.calidad7 === "0" ? false : null;;

                            // console.log("oc", cotizacion.orden_compra.fecha_orden.split("T"));
                            var fechaInicio = moment(cotizacion.ordenCompra.fechaOrden.split("T")[0] + " 00:00:00", "YYYY-MM-DD HH:mm:ss");
                            var fechaUltRecepcion = moment(fecha[2] + '-' + fecha[1] + '-' + fecha[0] + " 00:00:00", "YYYY-MM-DD HH:mm:ss");

                            // console.log("fecha inicio orig: ", fechaInicio.format("DD-MM-YYYY HH:mm:ss"));
                            for (var i = 0; i < cotizacion.plazoEntrega; i++) {
                                fechaInicio.add(1, 'd');
                                var diaSemana = fechaInicio.day();
                                if (cotizacion.tipo_plazo == "habil" && (diaSemana == 0 || diaSemana == 6)) {
                                    fechaInicio.add(1, 'd');
                                    diaSemana = fechaInicio.day();
                                    if (diaSemana == 0 || diaSemana == 6) fechaInicio.add(1, 'd');
                                }
                            }
                            
                            let diferencia = fechaUltRecepcion.diff(fechaInicio, 'days');
                            let cumpleEntrega = '0';
                            if (diferencia <= -2) cumpleEntrega = '1.2';
                            else if (diferencia <= -1) cumpleEntrega = '1.1';
                            else if (diferencia < 1) cumpleEntrega = '1';
                            else if (diferencia < 2) cumpleEntrega = '0.8';
                            else if (diferencia < 3) cumpleEntrega = '0.6';
                            formRecepcion.cumple_entrega = cumpleEntrega;
                        }
                        await agregarRecepcionItemRecurso(formRecepcion);
                        assignMensaje('Recepción realizada con éxito');
                        this.setState({
                            ...this.state,
                            openItemRecibido: false,
                            // loadingItemRecibido: false,
                            modal: true,
                            confirmacion: false,
                            open: true,
                            accion: false,
                            recepcionRealizada: true,
                        });
                        recargarDatos(false);
                    }
                }
            } catch (e) {
                assignMensaje('Ha ocurrido un error, vuelva a intentarlo. Error: ' + e.message);
                this.setState({
                    ...this.state,
                    openItemRecibido: false,
                    // loadingItemRecibido: false,
                    modal: true,
                    confirmacion: false,
                    open: true,
                    accion: false
                });
            }
        }
    }

    handleSepararItems = async values => {
        const { solicitudActual, idItem, addItems, editarItems, assignMensaje, fetchItemsCotizacion, updateField, recargarDatos } = this.props;
        let item = solicitudActual.items.filter(x => x.id === idItem)[0];
        if (item.cantidad > 1 && values.cantidad > 0) {
            if (values.cantidad < item.cantidad) {
                item = {
                    ...item,
                    fecha_requerida: moment(item.fecha_requerida).toISOString(),
                    cantidad: item.cantidad - values.cantidad
                };
                let newItem = {
                    ...item,
                    fecha_requerida: moment(item.fecha_requerida).toISOString(),
                    cantidad: values.cantidad
                };
                await editarItems(idItem, item);
                await addItems(newItem);
                await recargarDatos(false);
                await fetchItemsCotizacion(this.props.solicitudActual);
                assignMensaje('Items separados con exito');
                console.log(this.props.solicitudActual.items);
                let nuevoItem = this.props.solicitudActual.items[this.props.solicitudActual.items.length - 1];
                let idNuevoItem = nuevoItem.id;
                updateField("cotizacion", "descripcion{" + idNuevoItem + "}", nuevoItem.descripcion);
                updateField("cotizacion", idNuevoItem, "0");
                this.setState({
                    ...this.state,
                    openModalSeparar: false,
                    confirmacion: false,
                    open: true,
                    accion: false
                });
            } else {
                assignMensaje('La cantidad a separar no puede ser mayor que la cantidad de items');
                this.setState({
                    ...this.state,
                    openModalSeparar: false,
                    confirmacion: false,
                    open: true,
                    accion: false
                });
            }
        } else {
            assignMensaje('Para separar el item la cantidad debe ser mayor que 1');
            this.setState({ ...this.state, openModalSeparar: false, confirmacion: false, open: true, accion: false });
        }
    };

    // handleItemRecibido = async values => {
    //     const {
    //         items,
    //         idItem,
    //         fetchItemsCotizados,
    //         fetchItems,
    //         idSolicitud,
    //         assignMensaje,
    //         itemsCotizacionActual,
    //         consultarCotizacion,
    //         cotizacionSelected,
    //         agregarGuiaDespacho,
    //         agregarFactura,
    //         agregarRecepcionItemRecurso,
    //         usuarioAutenticado
    //     } = this.props;
    //     const item = items.data.filter(x => x.id === idItem)[0];
    //     let cotizacion = await consultarCotizacion(cotizacionSelected[0].id);
    //     let itemCotizacion = itemsCotizacionActual.data.filter(x => x.item_recurso_id === idItem)[0];
    //     let fecha = values.fecha.split('/');
    //     const formComprobante = {
    //         file: values.file,
    //         mime_type: values.file.type,
    //         name: values.file.name,
    //         size: values.file.size,
    //         chunk: values.file.size,
    //         numero: values.numero,
    //         fecha: fecha[2] + fecha[1] + fecha[0],
    //         orden_compra_id: cotizacion.orden_compra.id,
    //         monto: values.monto ? values.monto : ''
    //     };
    //     let comprobante;
    //     if (values.tipo_doc === 'Guía Despacho') {
    //         comprobante = await agregarGuiaDespacho(formComprobante);
    //     } else {
    //         comprobante = await agregarFactura({
    //             ...formComprobante,
    //             fecha_factura: formComprobante.fecha,
    //             monto: values.monto
    //         });
    //     }
    //     const formRecepcion = {
    //         cantidad: values.cantidad,
    //         fecha: moment().format('YYYY-MM-DD'),
    //         item_cotizacion_id: itemCotizacion.id,
    //         usuario_id: usuarioAutenticado.data.id
    //     };

    //     await agregarRecepcionItemRecurso(formRecepcion);
    //     //await fetchItemsCotizados(cotizacionSelected[0].id)
    //     await fetchItems(idSolicitud);
    //     assignMensaje('Recepción realizada con éxito');
    //     this.setState({
    //         ...this.state,
    //         openItemRecibido: false,
    //         modal: true,
    //         confirmacion: false,
    //         open: true,
    //         accion: false
    //     });
    // };

    handleCargarFactura = async values => {
        const { assignMensaje, agregarFactura, proveedores, solicitudActual, usuarioAutenticado } = this.props;
        this.setState({...this.state, uploadingFactura: true});
        let fecha = values.fecha.split('/');
        console.log("cargando factura", values);

        let cotizacion = solicitudActual.cotizaciones.find(x => x.ordenCompra?.id === values.orden_compra);
        let provCot= proveedores.find(x => x.id === cotizacion.proveedor_id);
        let datosProvFaltantes = '';
        if (provCot.nombre == null || provCot.nombre == "") datosProvFaltantes += '- Nombre \n';
        if (provCot.rut == null || provCot.rut == "") datosProvFaltantes += '- RUT \n';
        if (provCot.banco_nombre == null || provCot.banco_nombre == "") datosProvFaltantes += '- Nombre Banco \n';
        if (provCot.banco_tipo_cuenta == null || provCot.banco_tipo_cuenta == "") datosProvFaltantes += '- Tipo de Cuenta \n';
        if (provCot.banco_numero_cuenta == null || provCot.banco_numero_cuenta == "") datosProvFaltantes += '- Nº de Cuenta \n';
        if (provCot.banco_email == null || provCot.banco_email == "") datosProvFaltantes += '- Correo electrónico (bancario) \n';

        let token = await getCurrentUser();
        let facRend = await fetch('https://api-rendiciones.cydingenieria.com/facturas/?numero_factura_eq=' + values.numero + '&proveedor.rut_eq=' + provCot.rut + '&eliminado_eq=false', {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + token.token
            }
        });
        let dataFacRend = await facRend.json();
        
        if (values.estado_pago == 'Procesando Pago' && dataFacRend?.data && dataFacRend.data.length > 0) {
            let mensajeFac = 'No se puede cargar la factura y enviar a pago porque en el Sistema Gestor de ';
            mensajeFac += 'Pagos ya existe una factura con el número "' + values.numero + '" para el proveedor "';
            mensajeFac += provCot.nombre + '".';
            assignMensaje(mensajeFac);
            this.setState({
                openCargarFactura: false,
                confirmacion: false,
                open: true,
                accion: false
            });
        } else {
            if (datosProvFaltantes != '' && values.estado_pago == 'Procesando Pago') {
                let mensajeProv = 'No se puede cargar la factura y enviar a pago debido a datos faltantes del proveedor.';
                mensajeProv += ' Complete los siguientes datos faltantes en Gestión de Proveedores.\n\n';
                mensajeProv += datosProvFaltantes
                assignMensaje(mensajeProv);
                this.setState({
                    openCargarFactura: false,
                    confirmacion: false,
                    open: true,
                    accion: false
                });
            } else {
                try {
                    const formComprobante = {
                        numero: values.numero,
                        fecha: fecha[2] + fecha[1] + fecha[0],
                        orden_compra_id: values.orden_compra,
                        monto: values.monto ? values.monto.replace(/\./g, '') : 0
                    };

                    let momentFechaDoc = moment(fecha[2] + '-' + fecha[1] + '-' + fecha[0] + " 00:00:00", "YYYY-MM-DD HH:mm:ss");
                    let fechaDoc2 = momentFechaDoc.format();
                    momentFechaDoc.add(values.dias_vencimiento, 'd');
                    let fechaVenc = momentFechaDoc.format();

                    if (values.estado_pago == 'Procesando Pago') {
                        let ruta = "facturas/" + provCot.nombre + "/" + values.numero;
                        let dataArchivoStorage = await uploadFileToCloudStorage(values.file, ruta, values.file.name, "informa-pago-facturas");

                        let montoNeto = values.monto ? parseInt(values.monto.replace(/\./g, '')) : 0;
                        if (values.iva) montoNeto = montoNeto - parseInt(values.iva.replace(/\./g, ''));
                        // console.log("enviando a pago coti: ", cotizacion);
                        
                        let userAprobador = cotizacion.aprobadorGaf
                            ? cotizacion.aprobadorGaf
                            : cotizacion.aprobador
                            ? cotizacion.aprobador
                            : cotizacion.aprobadorCompras;
                        let empresa = await leer(`/empresa-cyd/${cotizacion.empresa_cyd_id}`);
                        let datosPago = {
                            estado: values.estado_pago,
                            tipo_rendicion: "Pago directo a proveedor (Orden giro)",
                            numero_factura: values.numero,
                            monto_total: values.monto ? parseInt(values.monto.replace(/\./g, '')) : 0,
                            monto_iva: values.iva ? parseInt(values.iva.replace(/\./g, '')) : 0,
                            monto_neto: montoNeto,
                            monto_total_dolares: 0,
                            clasificacion_factura: values.clasificacion_factura.nombre,
                            es_exenta: values.iva == '0',
                            descripcion: values.descripcion,
                            comentario: values.comentario,
                            dias_vencimiento: values.dias_vencimiento,
                            fecha_factura: fechaDoc2,
                            fecha_vencimiento: fechaVenc,
                            _contrato_revision_ref: null,
                            _gerencia_revision_ref: null,
                            _sociedad_ref: null,
                            contrato: solicitudActual.contrato._id,
                            gerencia: null,
                            sociedad: formatoRut(empresa.rut.replace('-', '')),
                            proveedor: {
                                nombre: provCot.nombre,
                                rut: provCot.rut,
                                correo_transferencia: provCot.banco_email,
                                numero_cuenta: provCot.banco_numero_cuenta,
                                tipo_cuenta: provCot.banco_tipo_cuenta,
                                banco: provCot.banco_nombre
                            },
                            codigo_orden_compra: cotizacion.ordenCompra.codigo,
                            codigo_solicitud_recurso: solicitudActual.codigo,
                            id_orden_compra: cotizacion.ordenCompra.id,
                            id_usuario_aprobador_sda: userAprobador.id,
                            aprobador_sda: userAprobador.nombres + ' ' + userAprobador.apellidos,
                            archivo_factura: dataArchivoStorage
                        }
                        console.log("datos pago:", datosPago);
                        let token = await getCurrentUser();
                        let response = await fetch('https://api-rendiciones.cydingenieria.com/facturas', {
                            method: 'POST',
                            headers: {
                                Accept: 'application/json',
                                'Content-Type': 'application/json',
                                Authorization: 'Bearer ' + token.token
                            },
                            body: JSON.stringify(datosPago)
                        });
                        const jsonResponse = await response.json();
                        if (response.status !== 200 && response.status !== 201 && response.status !== 204) {
                            let error;
                            if (jsonResponse && jsonResponse.errors) {
                                error = jsonResponse.errors;
                            }
                            console.log("errores: ", error);
                        }
                        console.log("resultado: ", jsonResponse);
                        token = await getCurrentUser();
                        let facRend = await fetch('https://api-rendiciones.cydingenieria.com/facturas/?numero_factura_eq=' + values.numero + '&proveedor.rut_eq=' + provCot.rut + '&eliminado_eq=false', {
                            method: 'GET',
                            headers: {
                                Authorization: 'Bearer ' + token.token
                            }
                        });
                        let dataFacRend = await facRend.json();
                        if (!dataFacRend?.data || dataFacRend.data.length == 0) {
                            Sentry.captureMessage(`Error factura no creada en Gestor de Pagos, N: ${values.numero}, ProvRut: ${provCot.rut}`)
                            throw new Error("Factura no creada en Gestor de Pagos.");
                        }
                    }

                    let respuesta = await uploadFileAsPromise(values.file, "1nXCYyLE4rEIB50q98yOBCaVc8e7Nikjx");
                    let comprobante = await agregarFactura({
                        ...formComprobante,
                        file_id: respuesta.id,
                        file_nombre: respuesta.nombre,
                        file_url: respuesta.url,
                        fecha_factura: formComprobante.fecha,
                        creador_id: usuarioAutenticado.data.id,
                        enviado_a_pago: values.estado_pago == 'Procesando Pago'
                    });

                    assignMensaje('Factura cargada correctamente');
                    this.setState({
                        openCargarFactura: false,
                        confirmacion: false,
                        open: true,
                        accion: false
                    });
                } catch (e) {
                    assignMensaje('Ha ocurrido un error, vuelva a intentarlo. Error: ' + e.message);
                    this.setState({
                        openCargarFactura: false,
                        confirmacion: false,
                        open: true,
                        accion: false
                    });
                }
            }
        }
    };

    handleNuevoProveedorModal = () => {
            this.setState({
                ...this.state,
                openFormProveedor: true,
                loadingFormProveedor: false,
                confirmacion: false,
                accion: false
            });
    }

    handleAgregarProveedoor = async (values) => {
        const { agregarProveedores, agregarSucursal, assignMensaje, proveedores, usuarioAutenticado, recargarDatos } = this.props;
        this.setState({ loadingFormProveedor: true });

        values.email_creador = usuarioAutenticado.data.email;
        if (values.telefono == "") values.telefono = null;
        if (values.contacto_telefono == "") values.contacto_telefono = null;
        if (proveedores.some(x => x.rut === values.rut)){
            values.proveedor_id = proveedores.find(x => x.rut == values.rut).id;
            console.log("proveedor existe, se crea la sucursal", values);
            await agregarSucursal(values);

            assignMensaje('Sucursal agregada');
            this.setState({ ...this.state, openFormProveedor: false, open: true, confirmacion: false, accion: false });
        } else {
            values.clase = values.clase.join(',');
            values.criticidad = values.criticidad ? values.criticidad : null;
            console.log("proveedor no existe, se crea proveedor", values);

            await agregarProveedores(values);
                
            assignMensaje('Proveedor agregado');
            this.setState({ ...this.state, openFormProveedor: false, open: true, confirmacion: false, accion: false });
        }
        recargarDatos(false);
    };

    renderAprobador = rowData => {
        if (rowData.aprobador && rowData.aprobador != '') {
            return (
                <Tooltip
                    enterTouchDelay={0}
                    title={
                        <div style={{ whiteSpace: ' normal', textAlign: 'center' }}>
                            {'Fecha: ' + rowData.fecha_aprobacion}
                        </div>
                    }>
                    <div>{rowData.aprobador}</div>
                </Tooltip>
            );
        } else {
            return '';
        }
    };

    renderAutorizaCompra = rowData => {
        if (rowData.rechazador && rowData.rechazador != '') {
            return (
                <Tooltip
                    enterTouchDelay={0}
                    title={
                        <div style={{ whiteSpace: 'normal', textAlign: 'center' }}>
                            {'Fecha: ' + rowData.fecha_rechazo}
                            {rowData.motivo_rechazo && <br/>}
                            {rowData.motivo_rechazo && ('Motivo: ' + rowData.motivo_rechazo)}
                        </div>
                    }>
                    <div>{rowData.rechazador}</div>
                </Tooltip>
            );
        } else if (rowData.revisaCentro && rowData.revisaCentro != '') {
            return (
                <Tooltip
                    enterTouchDelay={0}
                    title={
                        <div style={{ whiteSpace: ' normal', textAlign: 'center' }}>
                            {'Fecha: ' + rowData.fecha_revisaCentro}
                        </div>
                    }>
                    <div>{rowData.revisaCentro}</div>
                </Tooltip>
            );
        } else {
            return '';
        }
    };

    renderAutorizaGAF = rowData => {
        if (rowData.revisaGAF && rowData.revisaGAF != '') {
            return (
                <Tooltip
                    enterTouchDelay={0}
                    title={
                        <div style={{ whiteSpace: ' normal', textAlign: 'center' }}>
                            {'Fecha: ' + rowData.fecha_revisaGAF}
                        </div>
                    }>
                    <div>{rowData.revisaGAF}</div>
                </Tooltip>
            );
        } else {
            return '';
        }
    };

    renderMonitor = rowData => {
        let listadoNombres = '';
        rowData.data_original.responsables.forEach(x => {
            if (listadoNombres.length > 0) listadoNombres += '\n';
            listadoNombres += x;
        });
        if (listadoNombres.length == 0) {
            return '';
        } else {
            return (
                <Tooltip
                    enterTouchDelay={0}
                    title={
                        <div style={{ whiteSpace: 'pre', textAlign: 'left' }}>
                            {listadoNombres}
                        </div>
                    }>
                    <PersonIcon />
                </Tooltip>
            );
        }
    }

    renderComentario = rowData => {
        if (rowData.data_original.comentario) {
            return (
                <Tooltip
                    enterTouchDelay={0}
                    title={
                        <div style={{ whiteSpace: 'normal', textAlign: 'left' }}>
                            {rowData.data_original.comentario}
                        </div>
                    }>
                    <InfoOutlinedIcon color="primary" />
                </Tooltip>
            );
        } else {
            return '';
        }
    }

    render() {
        const {
            solicitudActual,
            idSolicitud,
            revisarCotizacion,
            proveedores,
            usuarioAutenticado,
            idGrupoCotizacion,
            itemsCotizados,
            mensaje,
            modoEdicion,
            permisoEvaluarCosto,
            formCotizacion,
            comprobanteRecepcion,
            formRecibir,
            formProveedores,
            formCargar,
            aprobador2,
        } = this.props;

        return (
            <Grid item xs={12}>
                <Box>
                    <Typography variant="h5" gutterBottom>
                        Solicitud de Recurso N° {solicitudActual ? solicitudActual.codigo : ''}
                    </Typography>
                </Box>
                <Box>
                    {revisarCotizacion && (
                        <Box>
                            <Grid>
                                <Button
                                    onClick={this.handleCrearGrupoCotizacionModal}
                                    variant="outlined"
                                    color="primary">
                                    Crear Nuevo Grupo de Cotización
                                </Button>
                                <Button
                                    onClick={this.handleSolicitarAprobacionModal}
                                    variant="outlined"
                                    color="primary">
                                    Solicitar Aprobación de Cotizaciones
                                </Button>
                                {solicitudActual.cotizaciones.filter(x => x.activo && x.estado_id === 23).length > 0 && (
                                    <Button onClick={this.handleCargarFacturaModal} variant="outlined" color="primary">
                                        Cargar Factura
                                    </Button>
                                )}
                            </Grid>
                        </Box>
                    )}

                    <Box>
                        {solicitudActual.cotizacionGrupos.map((x, id) => (
                            <Paper style={{ margin: '10px' }} key={x.id}>
                                <a onClick={this.handleIdGrupoCotizacion(x.id)} style={{ cursor: 'pointer' }}>
                                    <Typography variant="body1">
                                        <IconButton>
                                            <i className="material-icons">keyboard_arrow_down</i>
                                        </IconButton>
                                        <strong>Mostrar Cotizaciones Grupo {id + 1}</strong>
                                    </Typography>
                                </a>
                                {x.id === idGrupoCotizacion && (
                                    <Grid>
                                        <Grid item xs={12} lg={12}>
                                            <Grid container justify="center" alignItems="center">
                                                <Typography variant="body1">Cotizaciones del Grupo</Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} lg={12}>
                                            <Table
                                                search={false}
                                                toolbar={false}
                                                paging={false}
                                                sorting={false}
                                                columnas={[
                                                    {
                                                        title: 'Código',
                                                        field: 'codigo',
                                                        cellStyle: {
                                                            width: '115px',
                                                            textAlign: 'center',
                                                            padding: '0px'
                                                        },
                                                        headerStyle: {
                                                            textAlign: 'center',
                                                            padding: '0px'
                                                            // paddingLeft: '28px'
                                                        }
                                                    },
                                                    {
                                                        title: 'Fecha Orden',
                                                        field: 'fecha_orden',
                                                        cellStyle: {
                                                            width: '75px',
                                                            padding: '0px',
                                                            textAlign: 'center'
                                                        },
                                                        headerStyle: {
                                                            textAlign: 'center',
                                                            padding: '0px'
                                                            // paddingLeft: '28px'
                                                        }
                                                    },
                                                    {
                                                        title: 'Centro Logístico',
                                                        field: 'centro_logistico',
                                                        cellStyle: {
                                                            width: '85px',
                                                            padding: '0px',
                                                            textAlign: 'center'
                                                        },
                                                        headerStyle: {
                                                            textAlign: 'center',
                                                            padding: '0px'
                                                            // paddingLeft: '28px'
                                                        }
                                                    },
                                                    {
                                                        title: 'Proveedor',
                                                        field: 'proveedor',
                                                        cellStyle: {
                                                            // width: '10px',
                                                            padding: '0px',
                                                            textAlign: 'center'
                                                        },
                                                        headerStyle: {
                                                            padding: '0px',
                                                            textAlign: 'center'
                                                            // paddingLeft: '28px'
                                                        }
                                                    },
                                                    {
                                                        title: 'Monto Total',
                                                        field: 'monto_total',
                                                        cellStyle: {
                                                            width: '110px',
                                                            textAlign: 'center',
                                                            padding: '0px'
                                                        },
                                                        headerStyle: {
                                                            textAlign: 'center',
                                                            padding: '0px'
                                                            // paddingLeft: '28px'
                                                        }
                                                    },
                                                    {
                                                        title: 'Estado',
                                                        field: 'estado',
                                                        cellStyle: {
                                                            width: '90px',
                                                            padding: '0px',
                                                            textAlign: 'center'
                                                        },
                                                        headerStyle: {
                                                            padding: '0px',
                                                            textAlign: 'center'
                                                        }
                                                    },
                                                    {
                                                        title: '¿Quién Gestiona?',
                                                        field: 'monitor',
                                                        sorting: false,
                                                        cellStyle: {
                                                            width: '50px',
                                                            textAlign: 'center',
                                                            padding: '0px'
                                                        },
                                                        headerStyle: {
                                                            textAlign: 'center',
                                                            padding: '0px'
                                                        },
                                                        render: rowData => this.renderMonitor(rowData)
                                                    },
                                                    {
                                                        title: 'Tipo Compra',
                                                        field: 'tipo',
                                                        cellStyle: {
                                                            width: '55px',
                                                            padding: '0px',
                                                            textAlign: 'center'
                                                        },
                                                        headerStyle: {
                                                            padding: '0px',
                                                            textAlign: 'center'
                                                        }
                                                    },
                                                    {
                                                        title: 'Aprobación Compras',
                                                        cellStyle: {
                                                            width: '90px',
                                                            padding: '0px',
                                                            textAlign: 'center'
                                                        },
                                                        headerStyle: {
                                                            padding: '0px',
                                                            textAlign: 'center'
                                                        },
                                                        render: rowData => this.renderAutorizaCompra(rowData)
                                                    },
                                                    {
                                                        title: 'Aprobación Contrato',
                                                        field: 'aprobador',
                                                        cellStyle: {
                                                            width: '90px',
                                                            padding: '0px',
                                                            textAlign: 'center'
                                                        },
                                                        headerStyle: {
                                                            padding: '0px',
                                                            textAlign: 'center'
                                                        },
                                                        render: rowData => this.renderAprobador(rowData)
                                                    },
                                                    
                                                    {
                                                        title: 'Aprobación GAF',
                                                        field: 'revisaGAF',
                                                        cellStyle: {
                                                            width: '90px',
                                                            padding: '0px',
                                                            textAlign: 'center'
                                                        },
                                                        headerStyle: {
                                                            padding: '0px',
                                                            textAlign: 'center'
                                                        },
                                                        render: rowData => this.renderAutorizaGAF(rowData)
                                                    },
                                                    {
                                                        title: 'Orden de Compra',
                                                        field: 'orden_compra',
                                                        cellStyle: {
                                                            width: '120px',
                                                            padding: '0px',
                                                            textAlign: 'center'
                                                        },
                                                        headerStyle: {
                                                            padding: '0px',
                                                            textAlign: 'center'
                                                        }
                                                    },
                                                    {
                                                        title: 'Comentario Compra',
                                                        field: 'comentario',
                                                        sorting: false,
                                                        cellStyle: {
                                                            width: '50px',
                                                            textAlign: 'center',
                                                            padding: '0px'
                                                        },
                                                        headerStyle: {
                                                            textAlign: 'center',
                                                            padding: '0px'
                                                        },
                                                        render: rowData => this.renderComentario(rowData)
                                                    },
                                                    {
                                                        title: 'Acción',
                                                        field: 'accion',
                                                        cellStyle: {
                                                            width: '90px',
                                                            padding: '0px',
                                                            textAlign: 'center'
                                                        },
                                                        headerStyle: {
                                                            padding: '0px',
                                                            textAlign: 'center'
                                                        }
                                                    }
                                                ]}
                                                data={solicitudActual.cotizaciones
                                                    .filter(w => w.activo)
                                                    .filter(y => y.grupo_cotizacion_id === x.id)
                                                    .map(z => ({
                                                        data_original: { ...z },
                                                        tipo: z.es_gasto_reemb
                                                            ? 'Gasto Reembolsable'
                                                            : z.es_tarifa_gestion
                                                                ? 'Tarifa de Gestión'
                                                                : 'Varios',
                                                        codigo: z.codigo,
                                                        fecha_orden: moment(z.fecha_orden).format('DD-MM-YYYY'),
                                                        centro_logistico: z.ubicacionLogistica.nombre,
                                                        proveedor: z.proveedorSucursal.proveedor.nombre,
                                                        monto_total:
                                                            z.tipo_moneda +
                                                            '$ ' +
                                                            addMillsSep(z.monto_total, z.tipo_moneda),
                                                        monto_total_pesos:
                                                            z.tipo_moneda == 'CLP'
                                                                ? z.monto_total
                                                                : z.monto_total_pesos,
                                                        estado: z.estado.nombre,
                                                        aprobador: z.aprobador
                                                            ? `${z.aprobador?.nombre} ${z.aprobador?.apellidos}`
                                                            : '',
                                                        rechazador: z.rechazador
                                                            ? `${z.rechazador?.nombre} ${z.rechazador?.apellidos}`
                                                            : '',
                                                        revisaCentro: z.aprobadorCompras
                                                            ? `${z.aprobadorCompras?.nombre} ${z.aprobadorCompras?.apellidos}`
                                                            : '',
                                                        fecha_revisaCentro: z.fechaAprobacionCompras
                                                            ? moment(z.fechaAprobacionCompras).format('DD-MM-YYYY HH:mm:ss')
                                                            : '',
                                                        revisaGAF: z.aprobadorGaf
                                                            ? `${z.aprobadorGaf?.nombre} ${z.aprobadorGaf?.apellidos}`
                                                            : '',
                                                        fecha_revisaGAF: z.fechaAprobacionGaf
                                                            ? moment(z.fechaAprobacionGaf).format('DD-MM-YYYY HH:mm:ss')
                                                            : '',
                                                        fecha_aprobacion: z.fechaAprobacion
                                                            ? moment(z.fechaAprobacion).format('DD-MM-YYYY HH:mm:ss')
                                                            : '',
                                                        fecha_rechazo: z.fechaRechazo
                                                            ? moment(z.fechaRechazo).format('DD-MM-YYYY HH:mm:ss')
                                                            : '',
                                                        motivo_rechazo: z.motivoRechazo,
                                                        orden_compra: z.ordenCompra?.id
                                                        ? (
                                                            <Button
                                                                color="primary"
                                                                onClick={() => {
                                                                    this.handleVerDocumento(
                                                                        z.ordenCompra.archivoId,
                                                                        z.ordenCompra.codigo
                                                                    );
                                                                }}>
                                                                {z.ordenCompra.codigo}
                                                            </Button>
                                                        ) : (
                                                            ''
                                                        ),
                                                        accion: (
                                                            <BotonMenuCotizaciones
                                                                handleVerDocumento={this.handleVerDocumento}
                                                                revisarCotizacion={revisarCotizacion}
                                                                dataCotizacion={z}
                                                                aprobadorId={z.aprobador?._id}
                                                                revisaCentroId={z.aprobadorCompras?._id}
                                                                centroFirmaId={z.ubicacionLogistica.usuarioFirmaOC}
                                                                aprobadorRango2={
                                                                    solicitudActual.gerenteId === usuarioAutenticado.data.id
                                                                }
                                                                aprobador={aprobador2}
                                                                estado2={z.estado.orden}
                                                                id={z.id}
                                                                idArchivo={z.archivo_id}
                                                                handleCotizacionModal={this.handleCotizacionModal}
                                                                usuarioAutenticado={usuarioAutenticado}
                                                                tieneItemsRecepcion={
                                                                    this.state.listaItemsCotizados[z.id]
                                                                        ? z.estado_id === 23 && this.state.listaItemsCotizados[z.id].some(
                                                                              y => y.estado === 12
                                                                          )
                                                                        : false
                                                                }
                                                            />
                                                        )
                                                    }))}
                                            />
                                        </Grid>
                                        {revisarCotizacion && (
                                            <Grid
                                                style={{ margin: '20px' }}
                                                container
                                                justify="center"
                                                alignItems="center">
                                                <Button
                                                    onClick={this.handleCrearCotizacionModal}
                                                    variant="outlined"
                                                    color="primary">
                                                    Crear Nueva Cotización <i className="material-icons">add</i>
                                                </Button>
                                            </Grid>
                                        )}
                                    </Grid>
                                )}
                            </Paper>
                        ))}
                    </Box>
                </Box>

                <Modal
                    open={this.state.open}
                    handleClose={this.handleCloseMensaje}
                    confirmacion={this.state.confirmacion}
                    accion={this.state.accion}
                    loading={this.state.loading}>
                    <p style={{ whiteSpace: 'break-spaces' }}>{mensaje.data}</p>
                    {this.state.mostrarAyudaPDFProtegido && 
                        <div>
                            <a target="_blank" rel="noreferrer" href="https://drive.google.com/file/d/13z3vh4GiAfpnvW7MyaY86azueqevBjaU/view?usp=sharing">Como quitar bloqueo a un archivo pdf</a>
                        </div>
                    }
                </Modal>

                <Modal
                    open={this.state.openModalSeparar}
                    handleClose={this.handleClose}
                    confirmacion={this.state.confirmacion}
                    accion={this.state.accion}
                    loading={false}>
                    <FormSepararItems onSubmit={this.handleSepararItems} />
                </Modal>

                <Modal
                    xl={true}
                    fullWidth={true}
                    title="Nueva Cotización"
                    open={this.state.openCrearCotizacion}
                    handleClose={this.handleCloseCotizacion}
                    confirmacion={this.state.confirmacion}
                    accion={this.state.accion}
                    loading={false}>
                    {this.state.loadingCotizacion ? (
                        <Spinner />
                    ) : (
                        <FormCotizacion
                            modoEdicion={modoEdicion}
                            formCotizacion={formCotizacion}
                            cotizacionActual={solicitudActual?.cotizaciones?.find(x => x.oldId === this.state.idCotizacion)}
                            empresas={this.state.empresas}
                            centros={this.state.centrosUsuario}
                            paises={this.state.paises}
                            proveedores={proveedores}
                            onSubmit={this.handleCrearCotizacion}
                            handleEditarItemsModal={this.handleEditarItemsModal}
                            handleEliminarItemCotizacion={this.handleEliminarItemCotizacion}
                            itemsCotizacion={
                                modoEdicion
                                    ? itemsCotizados.filter(x =>
                                        solicitudActual?.cotizaciones?.find(x => x.oldId === this.state.idCotizacion)
                                            ?.item_cotizacion.some(y => y.item_recurso_id === x.id)
                                      )
                                    : itemsCotizados
                            }
                            handleNuevoProveedorModal={this.handleNuevoProveedorModal}
                        />
                    )}
                </Modal>

                <Modal
                    // title="Detalle Cotización"
                    showTitle={false}
                    xl={true}
                    fullWidth={true}
                    style={{ backgroundColor: '#f5f5f5' }}
                    open={this.state.openVerCotizacion}
                    handleClose={this.handleCloseCotizacion}
                    confirmacion={this.state.confirmacion}
                    accion={this.state.accion}
                    loading={false}>
                    {
                        <VerCotizacion
                            idSolicitud={idSolicitud}
                            idCotizacion={this.state.idCotizacion}
                            handleVerDocumento={this.handleVerDocumento}
                            handleRecepcionarTodoModal={this.handleRecepcionarTodoModal}
                            recepcionRealizada={this.state.recepcionRealizada}
                            revisarCotizacion={revisarCotizacion}
                            usuarioAutenticado={usuarioAutenticado}
                            permisoEvaluarCosto={permisoEvaluarCosto}
                        />
                    }
                </Modal>
                <Modal
                    open={this.state.openFormProveedor}
                    handleClose={() => { this.setState({ openFormProveedor: false }) }}
                    confirmacion={this.state.confirmacion}
                    accion={this.state.accion}
                    loading={this.state.loadingFormProveedor}
                    title="Formulario Proveedores"
                    fullWidth={true}
                    maxWidth="md"
                    showTitle={false}>
                        <FormProveedores
                            formProveedores={formProveedores}
                            editar={false}
                            proveedores={proveedores}
                            idSucEdit={false}
                            onSubmit={this.handleAgregarProveedoor}
                        />
                </Modal>
                <Modal
                    xl={false}
                    fullWidth={true}
                    title="Cargar Factura"
                    open={this.state.openCargarFactura}
                    handleClose={this.handleClose}
                    confirmacion={this.state.confirmacion}
                    accion={this.state.accion}>
                    {this.state.uploadingFactura ? (
                        <Spinner />
                    ) : (
                        <FormCargarFactura
                            ordenesCompra={solicitudActual.cotizaciones.filter(x => x.activo && x.estado_id === 23).map(x => x.ordenCompra)}
                            onSubmit={this.verificarArchivoFactura}
                            fileName={this.state.nomArchivoFactura}
                            handleOnChangeFileFactura={this.handleOnChangeFileFactura}
                            formCargar={formCargar}
                            items={solicitudActual.items}
                            cotizaciones={solicitudActual.cotizaciones.filter(x => x.activo && x.estado_id === 23)}
                        />
                    )}
                </Modal>
                <Modal
                    xl={false}
                    fullWidth={true}
                    title="Recepcionar ítems"
                    open={this.state.openItemRecibido}
                    handleClose={
                        comprobanteRecepcion.fetching
                            ? () => {}
                            : this.handleCloseItemRecibido
                    }
                    confirmacion={this.state.confirmacion}
                    accion={this.state.accion}
                    loading={this.state.loadingItemRecibido}>
                    <FormItemRecibido
                        entregados={this.state.textoItemsRecibidos}
                        max={this.state.maxItemsRecibidos}
                        onSubmit={this.verificarArchivoFactura}
                        fileName={this.state.comprobante}
                        handleOnChangeFileComprobante={this.handleOnChangeFileComprobante}
                        formRecibir={formRecibir}
                        todoRecibido={this.state.todoRecibido}
                        descripcion={this.state.descripcionFactura}
                        dataCotizacion={this.state.dataCotizacionRecepcion}
                        // tipoRecepcion={this.state.tipoRecepcion}
                    />
                </Modal>
                <Modal
                    open={this.state.openModalRechazo}
                    handleClose={this.handleClose}
                    confirmacion={this.state.confirmacion}
                    accion={this.state.accion}
                    loading={this.state.loading}>
                    <FormRechazoCotizacion onSubmit={this.handleRechazarCotizacion} />
                </Modal>
            </Grid>
        );
    }
}

const mapStateToProps = state => {
    return {
        usuarioAutenticado: state.Usuario,
        idItem: state.Iditem.data,
        itemsCotizados: state.ItemsCotizacion.data
            .filter(
                y =>
                    y.ubicacion_logistica ===
                    (state.form.cotizacion
                        ? state.form.cotizacion.values
                            ? state.form.cotizacion.values.ubicacion_logistica_id
                                ? state.form.cotizacion.values.ubicacion_logistica_id
                                : ''
                            : ''
                        : '')
            )
            .map(x => ({
                ...x,
                precio: state.form.cotizacion
                    ? state.form.cotizacion.values
                        ? state.form.cotizacion.values[x.id]
                            ? state.form.cotizacion.values[x.id]
                            : null
                        : null
                    : null
            })),
        mensaje: state.Mensajes,
        form: state.form,
        modoEdicion: state.ModoEdicion.data,
        idGrupoCotizacion: state.IdGrupoCotizacion.data,
        formCotizacion: state.form.cotizacion,
        formRecibir: state.form.recibirItems,
        formCargar: state.form.cargarFactura,
        permisoEvaluarCosto: state.PermisosUsuario.data.some(x => x.nombre === 'evaluarCostoProveedor'),
        revisarCotizacion: state.PermisosUsuario.data.some(x => x.nombre === 'crearCotizacion'),
        comprobanteRecepcion: state.ComprobanteRecepcion,
        formProveedores: state.form.proveedores ? state.form.proveedores.values : {}
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            ...solicitudesUsuarioDuck,
            ...solicitudesAprobarDuck,
            ...solicitudesDuck,
            ...idsolicitudDuck,
            ...iditemDuck,
            ...itemsDuck,
            ...aprobador1Duck,
            ...aprobador2Duck,
            ...vehiculoDuck,
            ...mensajesDuck,
            ...modoEdicionDuck,
            ...gruposCotizacionDuck,
            ...cotizacionesDuck,
            ...idGrupoCotizacionDuck,
            ...itemsCotizacionDuck,
            ...archivoDuck,
            ...itemsCotizadosDuck,
            ...proyectosGeneralDuck,
            ...gerenciasGeneralDuck,
            ...comprobanteRecepcionDuck,
            ...entregaItemRecurso,
            ...trasladoItemRecursoDuck,
            ...ordenesCompraDuck,
            ...proveedoresDuck,
            ...ciudadesDuck,
            ...aprobadoresGerenciaDuck,
            updateField: (form, field, newValue) => dispatch(change(form, field, newValue))
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(AdquisicionCotizacion);
