import { makeTypes, asyncMac, mac,  createReducer, reduceReducers } from 'ducks-maker'
import { createFetch, selectHOR } from './hors.js'
import { leer } from '../services/api'

const t = makeTypes('proyectos')
const FETCH = t('fetch').async()
const SELECT = t('select').single()
 
const fetchActions = asyncMac(FETCH)
export const selectProyecto = mac(SELECT,'payload')

const initialState = {
    data: [],
    fetched: false,
    fetching: false,
}

const fetchReducer = createReducer(initialState, createFetch( FETCH ))
const selectReducer = createReducer(initialState, selectHOR({ SELECT }))

export default reduceReducers(fetchReducer, selectReducer)

export const fetchProyectos = userId => async (dispatch, getState) => {
    dispatch(fetchActions.start())
    try {
        const data = await Promise.all([
            leer('/proyectosUsuario', userId),
            fetch('https://api-portafolio.cydingenieria.com/contratos')
        ]);
        let proyPortafolio = await data[1].json();
        let proyectosSoc = data[0].map(x => {
            let proyEncontrado = proyPortafolio.find(y => y._id === x.portafolio_id);
            return { ...x, sociedad: proyEncontrado ? proyEncontrado.sociedad_ref.nombre : ""  };
            });
        dispatch(fetchActions.success(proyectosSoc))
    } catch (error) {
        dispatch(fetchActions.error(error))
    }
}