import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    Typography,
    Grid,
    IconButton,
    TableCell,
    TableRow,
    Box,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    Tooltip
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { saveAs } from 'file-saver';
import { downloadUrlAsPromise, addMillsSep } from '../services/utils';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import ImagenStock from '../img/stock.png';
import ImagenCot from '../img/cot.png';
import ImagenOCAprob from '../img/oc_aprob.png';
import ImagenRecepcion from '../img/recepcion.png';
import ImagenPreparar from '../img/preparar.png';
import ImagenTraslado from '../img/traslado.png';
import ImagenListoEntrega from '../img/listo_entrega.png';
import ImagenEvalCosto from '../img/eval_costo.png';
import Tabs from '../components/Tabs';
// import Spinner from '../components/Spinner';
// import Detalle from '../components/Detalle';
import Modal from '../components/Modal';
// import Table from '../components/Table';
// import BotonMenuItems from '../components/BotonMenuItems';
import VerCotizacion from './VerCotizacion';
// import Tooltip from '@material-ui/core/Tooltip';
// import Card from '@material-ui/core/Card';
// import CardContent from '@material-ui/core/CardContent';
// import Button from '@material-ui/core/Button';
// import ExcelJS from 'exceljs/dist/exceljs';
import * as solicitudesUsuarioDuck from '../ducks/SolicitudesUsuario';
import * as solicitudesAprobarDuck from '../ducks/SolicitudesAprobar';
import * as solicitudesDuck from '../ducks/Solicitudes';
import * as idsolicitudDuck from '../ducks/Idsolicitud';
import * as iditemDuck from '../ducks/Iditem';
import * as itemsDuck from '../ducks/Items';
import * as itemsAllDuck from '../ducks/ItemsAll';
import * as aprobador1Duck from '../ducks/Aprobador1';
import * as aprobador2Duck from '../ducks/Aprobador2';
import * as vehiculoDuck from '../ducks/Vehiculos';
import * as mensajesDuck from '../ducks/Mensajes';
import * as modoEdicionDuck from '../ducks/ModoEdicion';
import * as gruposCotizacionDuck from '../ducks/GruposCotizacion';
import * as cotizacionesDuck from '../ducks/Cotizaciones';
import * as ordenesCompraDuck from '../ducks/OrdenesCompra';
import * as facturasDuck from '../ducks/Factura';
import * as idGrupoCotizacionDuck from '../ducks/IdGrupoCotizacion';
import * as itemsCotizacionDuck from '../ducks/ItemsCotizacion';
import * as archivoDuck from '../ducks/Archivo';
import * as itemsCotizadosDuck from '../ducks/ItemsCotizados';
import * as proyectosGeneralDuck from '../ducks/ProyectosGeneral';
import * as gerenciasGeneralDuck from '../ducks/GerenciasGeneral';
import * as comprobanteRecepcionDuck from '../ducks/ComprobanteRecepcion';
import * as trasladoItemRecursoDuck from '../ducks/TrasladoItemRecurso';
import * as entregaItemRecurso from '../ducks/EntregaItemRecurso';
import * as generalOptions from '../ducks/GeneralOptions';
import * as comentariosDuck from '../ducks/Comentarios';
import * as aprobadoresGerenciaDuck from '../ducks/AprobadoresGerencia';
import * as aprobadoresCotizacionDuck from '../ducks/AprobadoresCotizacion';
// import * as proveedoresDuck from '../ducks/Proveedores';
// import * as configuracionesDuck from '../ducks/ConfiguracionProyecto';
import { leer } from '../services/api'
// import moment from 'moment';
import 'moment/locale/es';

const styles = theme => ({
    kpiContainer: {
        height: 70
    },
    kpiValue: {
        textAlign: 'center',
        marginBottom: '0',
        marginTop: 20
    },
    kpiTitle: {
        textAlign: 'center',
        marginBottom: 0,
        fontSize: 12
    },
  });

class Solicitudes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            openModalRechazo: false,
            openModalSeparar: false,
            openModalEntregar: false,
            openCrearCotizacion: false,
            openVerCotizacion: false,
            openItemTraslado: false,
            openItemRecibido: false,
            openListoEntrega: false,
            openEvalCostoPendientes: false,
            confirmacion: false,
            accion: null,
            loading: false,
            mostrarFormulario: true,
            mostrarBusqueda: true,
            mostrarDetalle: false,
            loadingDetalle: true,
            comprobante: '',
            cantidadItemsEntregados: 0,
            textoItemsEntregados: '',
            maxItemsEntrega: 0,
            cantidadItemsRecibidos: 0,
            textoItemsRecibidos: '',
            maxItemsRecibidos: 0,
            textoItemsEnTraslado: '',
            maxItemsEnTraslado: 0,
            loadingSolicitud: false,
            detalleVisible: false,
            indicadoresEstado: {},
            idSolicitud: null,
            idCotizacion: null,
        };
    }

    shouldComponentUpdate(nextProps, nextState) {
        // console.log("actual:", this.state.loadingSolicitud);
        // console.log("next:", nextState.loadingSolicitud);
        // console.log("actual detalle visible: ", this.state.detalleVisible);
        // console.log("next detalle visible: ", nextState.detalleVisible);
        if (nextState.loadingSolicitud || (this.state.loadingSolicitud && !nextState.loadingSolicitud) || (this.state.detalleVisible && !nextState.detalleVisible)) {
            return false;
        } else {
            // console.log(this.props);
            // console.log(nextProps);
            return true;
        }
    }

    async componentDidMount() {
        const {
            fetchSolicitudesUsuario,
            fetchItemsTodos,
            fetchSolicitudesAprobar,
            fetchSolicitudes,
            fetchCotizaciones,
            fetchOrdenesCompra,
            fetchFacturas,
            fetchComentarios,
            usuarioAutenticado,
            solicitudes,
            solicitudesUsuario,
            solicitudesAprobar,
            cotizacionesTodas,
            ordenesCompra,
            facturas,
            fetchItemsCot,
            itemsCotizacion,
            items,
            comentarios,
            proveedores,
            permisosUsuario
        } = this.props;
        console.log("inicio fetch en solicitudes");
        this.cargarIndicadores();
        // if (!itemsCotizacion.fetching) {
        //     console.log("fetching items cotizacion!!!");
        //     fetchItemsCot();
        // }
        // if (!solicitudesAprobar.fetching && solicitudesAprobar.fetched && solicitudesUsuario.fetched) {
        //     console.log("fetch pendientes en solicitudes")
        //     fetchSolicitudesAprobar(usuarioAutenticado.data.id, '', '', '', '', '');
        // }
        // if (!solicitudes.fetching && solicitudes.fetched) {
        //     console.log("fetch sol en solicitudes");
        //     fetchSolicitudes(usuarioAutenticado.data.id, 'abiertas');
        //     // fetchItemsTodos();
        // }
        // if (!solicitudesUsuario.fetching && solicitudesUsuario.fetched) fetchSolicitudesUsuario(usuarioAutenticado.data.id, '', '', '', '', '');
        // if (cotizacionesTodas.data.length == 0) fetchCotizaciones();
        // if (ordenesCompra.data.length == 0) fetchOrdenesCompra();
        // if (facturas.length == 0) fetchFacturas();
        // if (items.data.length == 0 && !items.fetching) fetchItemsTodos();
        // if (comentarios.data.length == 0) fetchComentarios();
        // await Promise.all([
        //     // fetchSolicitudes(usuarioAutenticado.data.id),
        //     fetchCotizaciones(),
        //     fetchOrdenesCompra(),
        //     fetchFacturas(),
        //     fetchItemsCot(),
        //     fetchSolicitudesUsuario(usuarioAutenticado.data.id, '', '', '', '', ''),
        //     // fetchSolicitudesAprobar(usuarioAutenticado.data.id, '', '', '', '', '')
        // ]);
        console.log("fin fetch solicitudes");
        // let provRepetidos = [];
        // let rutProv = [];
        // proveedores.data.forEach(x => {
        //     if (rutProv.indexOf(x.rut) > -1) {
        //         provRepetidos.push(x);
        //     } else {
        //         rutProv.push(x.rut);
        //     }
        // });
        // console.log("prov repetidos: ", provRepetidos);
        // const { solicitudes, cotizacionesTodas, ordenesCompra, facturas, itemsCotizacion, proyectos, gerencias, editarProyecto, agregarAprobadorGerencia, proyectosTodos, aprobadoresCotizacionTodos, agregarAprobadorCotizacion, editarAprobadorCotizacion, editarCotizaciones, editarItems } = this.props;
        // let proyectosTodos = this.props.proyectosTodos;
        // console.log("proyectos: ", proyectosTodos);
        // let responsePortafolio = await fetch('https://api-portafolio.cydingenieria.com/contratos', {
        //     method: 'GET'
        // });
        // let contratosPortafolio = await responsePortafolio.json();
        // console.log("proyectos Portafolio: ", contratosPortafolio);
        // let cant0 = 0;
        // let cant1 = 0;
        // let cant2 = 0;
        // // contratosPortafolio.forEach(x => {
        // for (let i = 0; i < contratosPortafolio.length; i++) {
        // // for (let i = 0; i < 5; i++) {
        //     let x = contratosPortafolio[i];
        
        //     let codArr = x.codigo.split('-');
        //     let codNum = codArr.length === 3 ? codArr[2] : 'nada';
        //     if (proyectosTodos.data.filter(y => y.portafolio_id === x._id).length === 1) {
        //         cant0++;
        //         // let proy = proyectosTodos.data.find(y => y.codigo === x.codigo);
        //         // console.log("actualizar contrato ", cant0 + cant1, proy.id, x._id);
        //         // await this.props.editarProyecto(proy.id, { portafolio_id: x._id });
        //         // console.log("proy actualizado");
        //     // } else if (proyectosTodos.data.filter(y => y.codigo.includes(codNum)).length === 1) {
        //     //     cant1++;
        //         // let proy = proyectosTodos.data.find(y => y.codigo.includes(codNum));
        //         // console.log("actualizar contrato ", cant0 + cant1, proy.id, x._id);
        //         // await this.props.editarProyecto(proy.id, { portafolio_id: x._id });
        //         // console.log("proy actualizado");
        //         // console.log("proy encontrado solo num: ", x.codigo);
        //     } else {
        //         cant2++;
        //         console.log("proy no encontrado: ", x.codigo);
        //     }
        // }
        // console.log("encontrados: ", cant0, cant1);
        // console.log("no encontrados: ", cant2);
        // console.log("aprobadores Cotizacion: ", aprobadoresCotizacionTodos);

        // console.log("coti:", this.props.cotizacionesTodas);
        // let listado = [];
        // for (let i = 0; i < 2; i++) {
        // for (let i = 0; i < this.props.cotizacionesTodas.data.length; i++) {
        //     let coti = this.props.cotizacionesTodas.data[i];
        //     if (!coti.sucursal_id && listado.length < 200) {
        //         // console.log("no tiene sucursal");
        //         let update = {
        //             id: coti.id,
        //             sucursal_id: proveedores.data.find(x => x.id === coti.proveedor_id).sucursales[0].id
        //         }
        //         listado.push(update);
        //     }
            // let nuevaSuc = {
            //     "proveedor_id" : prov.id,
            //     "pais" : "",
            //     "region" : "",
            //     "ciudad_id" : prov.ciudad_id,
            //     "comuna_id" : prov.comuna_id,
            //     "direccion" : prov.direccion,
            //     "telefono" : prov.telefono ? prov.telefono : null,
            //     "pagina_web" : null,
            //     "email_envio_oc" : prov.email_envio_oc,
            //     "contacto_nombre" : null,
            //     "contacto_email" : null,
            //     "contacto_telefono" : null,
            //     "email_creador" : "noyarce@cydingenieria.cl",
            // }
            
            // await agregarSucursal(nuevaSuc);
            // console.log("agregada nueva suc");
        // }
        // console.log("listado final: ", listado);
        // if (listado.length > 0) {
        //     crear('/updateMasivoCotizacion', { listado: listado }).then(() => {
        //         console.log("terminó update coti");
        //     }).catch(error => {
        //         console.log("error update coti", error);
        //     });
        // } else {
        //     console.log("nada para actualizar");
        // }
        
        // let proyFilt = proyectosTodos.data.filter(x => x.gerencia === "GMI");
        // let solicitudesFilt = solicitudes.data.filter(x => proyFilt.some(y => y.id === x.proyecto));
        // let proyFilt = this.props.proyectosTodos.data.filter(x => x.activo);
        // let solicitudesFilt = this.props.solicitudesUsuario.data.filter(x => x.activo);
        // console.log("proyectos: ", proyFilt);
        // console.log("sol filt: ", solicitudesFilt);

        // let cotFilt = this.props.cotizacionesTodas.data.filter(x => x.activo && solicitudesFilt.some(y => y.id === x.solicitud_recurso_id));
        // console.log("coti filt: ", cotFilt);
        // console.log("items cot: ", this.props.itemsCotizacion);
        // let ocFilt = this.props.ordenesCompra.data.filter(x => cotFilt.some(y => y.id === x.cotizacion_id));
        // console.log("oc filt: ", ocFilt);

        // let facFilt = this.props.facturas.filter(x => ocFilt.some(y => y.id === x.orden_compra_id));
        // console.log("fac filt: ", facFilt);

        // console.log("prooved: ", this.props.proveedores);
        // let col = [];
        // Object.entries(solicitudesFilt[0]).forEach(function (prop) {
        //     col.push(prop[0]);
        // });
        // console.log("cols: ", col);
        // var crearExcel = false;
        // if (crearExcel) {
        //     let data = [];
        //     const wb = new ExcelJS.Workbook();
        //     const wsCot = wb.addWorksheet('Cotizaciones');
        //     const wsItemCot = wb.addWorksheet('ItemsCotizaciones');
        //     const wsOC = wb.addWorksheet('OC');
        //     const wsFacturas = wb.addWorksheet('Facturas');
        //     const wsProveedor = wb.addWorksheet('Proveedor');
        //     const wsProyecto = wb.addWorksheet('Proyectos');
        //     const ws = wb.addWorksheet('Solicitudes');

        //     let col = [];
        //     Object.entries(solicitudesFilt[0]).forEach(function (prop) {
        //         if (prop[0] != "items") {
        //             col.push({ header: prop[0]});
        //         }
        //     });
        //     ws.columns = [...col];
        //     solicitudesFilt.forEach(function (dato) {
        //         let values = [];
        //         Object.entries(dato).forEach(function (prop) {
        //             if (prop[0] != "items") {
        //                 values.push(prop[1]);
        //             }
        //         });
        //         ws.addRow(values);
        //     });

        //     col = [];
        //     Object.entries(cotFilt[0]).forEach(function (prop) {
        //         if (prop[0] != "evaluaciones_calidad" && prop[0] != "evaluaciones_costo" && prop[0] != "evaluaciones_entrega") {
        //             col.push({ header: prop[0]});
        //         }
        //     });
        //     wsCot.columns = [...col];
        //     cotFilt.forEach(function (dato) {
        //         let values = [];
        //         Object.entries(dato).forEach(function (prop) {
        //             if (prop[0] != "evaluaciones_calidad" && prop[0] != "evaluaciones_costo" && prop[0] != "evaluaciones_entrega") {
        //                 if (prop[0] == "estado") {
        //                     values.push(prop[1].nombre);
        //                 } else {
        //                     values.push(prop[1]);
        //                 }
        //             }
        //         });
        //         wsCot.addRow(values);
        //     });

        //     col = [];
        //     Object.entries(this.props.itemsCotizacion.data[0]).forEach(function (prop) {
        //         // if (prop[0] != "items") {
        //             col.push({ header: prop[0]});
        //         // }
        //     });
        //     wsItemCot.columns = [...col];
        //     this.props.itemsCotizacion.data.forEach(function (dato) {
        //         let values = [];
        //         Object.entries(dato).forEach(function (prop) {
        //             if (prop[0] == "estado") {
        //                 values.push(prop[1].nombre);
        //             } else {
        //                 values.push(prop[1]);
        //             }
        //         });
        //         wsItemCot.addRow(values);
        //     });

        //     col = [];
        //     Object.entries(ocFilt[0]).forEach(function (prop) {
        //         // if (prop[0] != "items") {
        //             col.push({ header: prop[0]});
        //         // }
        //     });
        //     wsOC.columns = [...col];
        //     ocFilt.forEach(function (dato) {
        //         let values = [];
        //         Object.entries(dato).forEach(function (prop) {
        //             // if (prop[0] != "items") {
        //                 values.push(prop[1]);
        //             // }
        //         });
        //         wsOC.addRow(values);
        //     });

        //     col = [];
        //     Object.entries(facFilt[0]).forEach(function (prop) {
        //         if (prop[0] != "ordenCompra") {
        //             col.push({ header: prop[0]});
        //         }
        //     });
        //     wsFacturas.columns = [...col];
        //     facFilt.forEach(function (dato) {
        //         let values = [];
        //         Object.entries(dato).forEach(function (prop) {
        //             if (prop[0] != "ordenCompra") {
        //                 values.push(prop[1]);
        //             }
        //         });
        //         wsFacturas.addRow(values);
        //     });

        //     col = [];
        //     Object.entries(this.props.proveedores.data[0]).forEach(function (prop) {
        //         if (prop[0] != "sucursales" && prop[0] != "trabajadores" && prop[0] != "vehiculos" && prop[0] != "evaluaciones_calidad" && prop[0] != "evaluaciones_costo" && prop[0] != "evaluaciones_entrega") {
        //             col.push({ header: prop[0]});
        //         }
        //     });
        //     wsProveedor.columns = [...col];
        //     this.props.proveedores.data.forEach(function (dato) {
        //         let values = [];
        //         Object.entries(dato).forEach(function (prop) {
        //             if (prop[0] != "sucursales" && prop[0] != "trabajadores" && prop[0] != "vehiculos" && prop[0] != "evaluaciones_calidad" && prop[0] != "evaluaciones_costo" && prop[0] != "evaluaciones_entrega") {
        //                 values.push(prop[1]);
        //             }
        //         });
        //         wsProveedor.addRow(values);
        //     });

        //     col = [];
        //     Object.entries(proyFilt[0]).forEach(function (prop) {
        //         // if (prop[0] != "items") {
        //             col.push({ header: prop[0]});
        //         // }
        //     });
        //     wsProyecto.columns = [...col];
        //     proyFilt.forEach(function (dato) {
        //         let values = [];
        //         Object.entries(dato).forEach(function (prop) {
        //             // if (prop[0] != "items") {
        //                 values.push(prop[1]);
        //             // }
        //         });
        //         wsProyecto.addRow(values);
        //     });
        
        //     ws.getRow(1).font = { bold: true };
        //     wsCot.getRow(1).font = { bold: true };
        //     wsItemCot.getRow(1).font = { bold: true };
        //     wsOC.getRow(1).font = { bold: true };
        //     wsFacturas.getRow(1).font = { bold: true };
        //     wsProveedor.getRow(1).font = { bold: true };
        //     wsProyecto.getRow(1).font = { bold: true };
        
        //     wb.xlsx
        //       .writeBuffer()
        //       .then(function (bufferData) {
        //         // console.log("exito en crear buffer");
        //         var blob = new Blob([bufferData], {
        //           type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        //         });
        //         saveAs(blob, 'Compras CyD ' + moment().format('YYYY-MM-DD HH:mm:ss') + '.xlsx');
        //       })
        //       .catch(function (error) {
        //         console.log('error en excel:', error);
        //       });
        // }
        
        // var crearExcel = true;
        // const { proyectosTodos } = this.props;
        // await this.props.fetchConfiguracionProyecto();
        // if (crearExcel) {
        //     const { centros, usuarios } = this.props;
        //     const wb = new ExcelJS.Workbook();
        //     const ws = wb.addWorksheet('Aprob SR');
        //     const wsCot = wb.addWorksheet('Aprob Cot');
        //     // const wsItemCot = wb.addWorksheet('ItemsCotizaciones');
        //     // const wsOC = wb.addWorksheet('OC');
        //     // const wsFacturas = wb.addWorksheet('Facturas');
        //     // const wsProveedor = wb.addWorksheet('Proveedor');
        //     // const wsProyecto = wb.addWorksheet('Proyectos');
            
        //     let col = [{ header: "Contrato" }, { header: "Aprobador" }, { header: "Tipo" }];
        //     ws.columns = [...col];

        //     this.props.configuraciones.data.forEach(function (dato) {
        //         dato.aprobador1.forEach(aprob => {
        //             let values = [];
        //             values.push(proyectosTodos.data.find(x => x.id === dato.proyecto_id)?.codigo);
        //             values.push(aprob.nombres + " " + aprob.apellidos);
        //             values.push("Proyecto");
        //             ws.addRow(values);
        //         });
        //         dato.aprobador2.forEach(aprob => {
        //             let values = [];
        //             values.push(proyectosTodos.data.find(x => x.id === dato.proyecto_id)?.codigo);
        //             values.push(aprob.nombres + " " + aprob.apellidos);
        //             values.push("Gerencia");
        //             ws.addRow(values);
        //         });
        //     });

        //     col = [{ header: "Contrato" }, { header: "Aprobador" }, { header: "Rango" }, { header: "Centro Logístico" }];
        //     // Object.entries(this.props.aprobadoresCotizacionTodos[0]).forEach(function (prop) {
        //     //     if (prop[0] != "items") {
        //     //         col.push({ header: prop[0]});
        //     //     } else {
        //     //         col.push({ header: 'usuario'});
        //     //     }
        //     // });
        //     wsCot.columns = [...col];
            
        //     this.props.aprobadoresCotizacionTodos.forEach(function (dato) {
        //         let values = [];
        //         values.push(proyectosTodos.data.find(x => x.id === dato.proyecto_id)?.codigo);
        //         let usuarioAprob = usuarios.find(x => x.id === dato.usuario_id);
        //         let nombre = usuarioAprob ? usuarioAprob.nombres + " " + usuarioAprob.apellidos : "";
        //         values.push(nombre);
        //         values.push(dato.rango_aprobacion_cotizacion_id);
        //         values.push(centros.data.find(x => x.id === dato.centro_logistico_id)?.nombre);
        //         // Object.entries(dato).forEach(function (prop) {
        //         //     // if (prop[0] != "items") {
        //         //         values.push(prop[1]);
        //         //     // }
        //         // });
        //         wsCot.addRow(values);
        //     });

        //     ws.getRow(1).font = { bold: true };
        //     wsCot.getRow(1).font = { bold: true };
        //     // wsItemCot.getRow(1).font = { bold: true };
        //     // wsOC.getRow(1).font = { bold: true };
        //     // wsFacturas.getRow(1).font = { bold: true };
        //     // wsProveedor.getRow(1).font = { bold: true };
        //     // wsProyecto.getRow(1).font = { bold: true };

        //     wb.xlsx
        //       .writeBuffer()
        //       .then(function (bufferData) {
        //         // console.log("exito en crear buffer");
        //         var blob = new Blob([bufferData], {
        //           type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        //         });
        //         saveAs(blob, 'Aprobadores SDA ' + moment().format('YYYY-MM-DD HH:mm:ss') + '.xlsx');
        //       })
        //       .catch(function (error) {
        //         console.log('error en excel:', error);
        //       });
        // }

        // let aprobCiro = this.props.aprobadoresCotizacionTodos.filter(x => x.usuario_id === "206viHQbHHMtb6cVBU8uZEVNenh1" && x.rango_aprobacion_cotizacion_id === 1);
        // console.log("aprob lrecabarren: ", aprobCiro);
        // for (let i = 0; i < aprobCiro.length; i++) {
        // // for (let i = 0; i < 3; i++) {
        //     await this.props.editarAprobadorCotizacion(aprobCiro[i].id, {usuario_id: "uFfZJLGMd1UaoTVzqF98duHsv1N2"})
        //     // let nuevoAprob = {
        //     //     proyecto_id: aprobCiro[i].proyecto_id,
        //     //     usuario_id: "206viHQbHHMtb6cVBU8uZEVNenh1",
        //     //     rango_aprobacion_cotizacion_id: aprobCiro[i].rango_aprobacion_cotizacion_id,
        //     //     centro_logistico_id: aprobCiro[i].centro_logistico_id
        //     // };
        //     // await this.props.agregarAprobadorCotizacion(nuevoAprob);
        //     // await borrar('/aprobador-cotizacion', aprobCiro[i].id);
        //     console.log("aprob cot listo");
        // }
        // console.log("fin");

        // let proyFilt = proyectosTodos.data.filter(x => x.gerencia === "GEI");
        // console.log("proyectos GEI:", proyFilt);
        // let aprobGEI = aprobadoresCotizacionTodos.filter(x => proyFilt.some(y => y.id === x.proyecto_id) && x.rango_aprobacion_cotizacion_id === 2 && x.usuario_id != "xXz6tqvKIyP7LfmKcqMxivYwpaG2");
        // console.log("aprob GEI", aprobGEI);
        // let distintos = [];
        // for (var i = 0; i < aprobGEI.length; i++) {
            // if (distintos.indexOf(aprobGEI[i].usuario_id) == -1) distintos.push(aprobGEI[i].usuario_id);
        //     await editarAprobadorCotizacion(aprobGEI[i].id, {usuario_id: "xXz6tqvKIyP7LfmKcqMxivYwpaG2"})
        //     console.log("aprob cot listo");
        // }
        // console.log("fin");
        // let cotFilt2 = cotizacionesTodas.data.filter(x => x.aprobador_id == "dScob6nyOuXdNor2FGyUCU6F4LU2" && !x.revisa_gg_id &&  x.estado_id == 23 && x.tipo_moneda != "CLP" && x.monto_total_pesos >= 5000000);
        // console.log("filtradas: ", cotizacionesTodas.data.filter(x => x.estado_id == 23));
        // console.log("filtradas con aprob: ", cotizacionesTodas.data.filter(x => x.aprobador_id && x.estado_id == 23));
        // console.log("proyectos filtrados: ", proyFilt);
        // console.log("filtradas 2: ", cotFilt2);

        // console.log("aprob cot: ", aprobadoresCotizacionTodos);
        // console.log("solicitudes: ", solicitudes);

        // console.log("mis proyectos: ", proyectos);
        // console.log("mis gerencias:", gerencias);
        // for (let i = 0; i < proyFilt.length; i++) {
        //     let dataProy = proyFilt[i];

        //     console.log("actualizando: ", dataProy.id);
        //     const aprobador = { aprobador1: [], aprobador2: [{ id: 'DZm2thKkPBNllrvGmpI54qVzJj13' }] };
        //     await agregarAprobadorGerencia(dataProy.id, aprobador);
        //     console.log("agregado aprobador 2");
        // }

        // let cantOK = 0;
        // let cantFaltan = 0;
        // let cantFalta1 = 0;
        // let cantFalta2 = 0;
        // for (let i = 0; i < proyFilt.length; i++) {
        //     let dataProy = proyFilt[i];
        //     let aprobadores = aprobadoresCotizacionTodos.filter(x => x.proyecto_id === dataProy.id && x.centro_logistico_id === 3);
        //     // let aprobadores1 = aprobadoresCotizacionTodos.filter(x => x.proyecto_id === dataProy.id && x.centro_logistico_id === 2 && x.rango_aprobacion_cotizacion_id === 1);
        //     // let aprobadores2 = aprobadoresCotizacionTodos.filter(x => x.proyecto_id === dataProy.id && x.centro_logistico_id === 2 && x.rango_aprobacion_cotizacion_id === 2);
            
        //     // if (aprobadores1[0].usuario_id != "DDWqFzcVTOg7hufYP4BuNacEDZ33") {
        //     //     cantFalta1++;
        //     //     console.log("actualizando: ", aprobadores1[0]);
        //     //     // await editarAprobadorCotizacion(aprobadores1[0].id, {usuario_id: "DDWqFzcVTOg7hufYP4BuNacEDZ33"})
        //     //     console.log("aprob cot listo");
        //     // }
        //     // if (aprobadores2[0].usuario_id != "DZm2thKkPBNllrvGmpI54qVzJj13") {
        //     //     cantFalta2++;
        //     //     console.log("actualizando: ", aprobadores2[0]);
        //     //     // await editarAprobadorCotizacion(aprobadores2[0].id, {usuario_id: "DZm2thKkPBNllrvGmpI54qVzJj13"})
        //     //     console.log("aprob cot listo");
        //     // }
        //     // if (aprobadores.length > 0) {
        //     //     cantOK++;
        //     //     console.log("OK: ", aprobadores);
        //     // } else {
        //     //     cantFaltan++;
        //     //     console.log("falta aprob: ", aprobadoresCotizacionTodos.filter(x => x.proyecto_id === dataProy.id));
        //     //     let nuevoAprobador1 = {
        //     //         proyecto_id: dataProy.id,
        //     //         usuario_id: "DDWqFzcVTOg7hufYP4BuNacEDZ33",
        //     //         rango_aprobacion_cotizacion_id: 1,
        //     //         centro_logistico_id: 3
        //     //     };
        //     //     let nuevoAprobador2 = {
        //     //         proyecto_id: dataProy.id,
        //     //         usuario_id: "rY60MUh3zkOBQ7xVWCDdkPD7fNq1",
        //     //         rango_aprobacion_cotizacion_id: 2,
        //     //         centro_logistico_id: 3
        //     //     };

        //     //     // await agregarAprobadorCotizacion(nuevoAprobador1);
        //     //     // await agregarAprobadorCotizacion(nuevoAprobador2);
        //     //     // console.log("agregados");
        //     // }
        // }
        // console.log("faltan: ", cantFalta1, cantFalta2);
        // console.log("proy OK: ", cantOK);
        // console.log("proy faltan: ", cantFaltan);

        // for (var i = 0; i < itemsFilt.length; i++) {
        //     let x = itemsFilt[i];
        //     // console.log(x.equipo_estandar);
        //     let nuevoEstandar = x.equipo_estandar;
        //     if (x.equipo_estandar.includes('Procesador i5-10210U')) {
        //         nuevoEstandar = 'Administrativo (HP Probook 440 G7, Procesador i5-10210U, Ram 16 GB, SSD 500 GB, Led 14", Windows 10 Pro)';
        //     } else if (x.equipo_estandar.includes('Procesador i5-8500')) {
        //         nuevoEstandar = 'Administrativo (HP Prodesk 400 G5, Procesador i5-8500, 16GB RAM, 256GB SSD + 1TB HDD, Windows 10 Pro)';
        //     } else if (x.equipo_estandar.includes('Ejecutivo') && x.equipo_estandar.includes('Procesador i7-8565U')) {
        //         nuevoEstandar = 'Ejecutivo (HP Elitebook 840 G6, Procesador i7-8565U, 16 GB DDR4, 512GB SSD, Pantalla 14”, Windows 10 Pro)';
        //     } else if (x.equipo_estandar.includes('Especialista AutoCAD') && x.equipo_estandar.includes('Procesador i7-8565U')) {
        //         nuevoEstandar = 'Especialista AutoCAD (Lenovo ThinkPad T490, Procesador i7-8565U, Ram 16GB, 512GB SSD, GeForce MX250, 14.0", Windows 10)';
        //     } else if (x.equipo_estandar.includes('Procesador i7-9750H')) {
        //         nuevoEstandar = 'Especialista BIM (Workstation Mobile Lenovo ThinkPad P53, Procesador i7-9750H, Tarjeta Video Quadro T1000, 32GB DDR4, 1TB SSD, Pantalla 15.6“, Windows 10 Pro)';
        //     } else if (x.equipo_estandar.includes('Intel® Xeon® Processor W-2102')) {
        //         nuevoEstandar = 'Especialista BIM (HP Worksation Z4 G4, Intel® Xeon® Processor W-2102, 2.9 GHz 4 nucleos, Tarjeta Video Quadro P2000, 32GB DDR4, 512GB SSD + 1TB, Windows 10 Pro)';
        //     }
        //     console.log(nuevoEstandar);
        //     await editarItems(x.id, {
        //         equipo_estandar: nuevoEstandar,
        //     });
        // }

        // for(var i = 0; i < cotFilt.length; i++) {
        // // for(var i = 0; i < 30; i++) {
        //     let x = cotFilt[i];
        //     let fechaOC = ordenesCompra.data.filter(y => y.cotizacion_id == x.id)[0].created_at;
        //     // if (!fechaOC || fechaOC.length == 0) console.log("no tiene fecha: ", x.id);
        //     let rango = 1;
        //     let aprobador = "DDWqFzcVTOg7hufYP4BuNacEDZ33";
        //     if (x.tipo_moneda == "CLP") {
        //         if (x.monto_total >= 5000000) {
        //             aprobador = "dScob6nyOuXdNor2FGyUCU6F4LU2";
        //         } else if (x.monto_total >= 1000000) {
        //             let proy = solicitudes.data.filter(y => y.id == x.solicitud_recurso_id)[0].proyecto;
        //             aprobador = aprobadoresCotizacionTodos.filter(y => y.proyecto_id == proy && y.rango_aprobacion_cotizacion_id == 2)[0].usuario_id;
        //         }
        //     } else {
        //         if (x.monto_total_pesos >= 5000000) {
        //             aprobador = "dScob6nyOuXdNor2FGyUCU6F4LU2";
        //         } else if (x.monto_total_pesos >= 1000000) {
        //             let proy = solicitudes.data.filter(y => y.id == x.solicitud_recurso_id)[0].proyecto;
        //             aprobador = aprobadoresCotizacionTodos.filter(y => y.proyecto_id == proy && y.rango_aprobacion_cotizacion_id == 2)[0].usuario_id;
        //         }
        //     }
        //     console.log("nueva data: ", { id: x.id, aprobador: aprobador, fecha: fechaOC });
        //     // console.log("aprobador: ", aprobador);
        //     // console.log("fecha: ", fechaOC);

        //     // await editarCotizaciones(x.id, {
        //     //     aprobador_id: aprobador,
        //     //     fecha_aprobacion: fechaOC
        //     // });
        // }
        // console.log("fin");
    }

    cargarIndicadores = () => {
        leer(`/getIndicadoresEstado/${this.props.usuarioAutenticado.data.id}`).then(resultado => {
            console.log("get indicadores", resultado);
            this.setState({
                indicadoresEstado: {
                    revStock: resultado.revStock,
                    cotizacion: resultado.cotizacion,
                    aprobOC: resultado.aprobOC,
                    recepcionOC: resultado.recepcionOC,
                    preparacion: resultado.preparacion,
                    traslado: resultado.traslado,
                    listoEntrega: resultado.listoEntrega,
                    evalCosto: resultado.evalCosto,
                }
            });
        });
    }

    //******** MODALES ************
    handleClose = () => {
        this.setState({
            ...this.state,
            openItemRecibido: false,
            openItemTraslado: false,
            openVerCotizacion: false,
            accion: false,
            confirmacion: false,
            open: false,
            openModalRechazo: false,
            openModalSeparar: false,
            openModalEntregar: false,
            openListoEntrega: false
        });
    };

    handleCloseCotizacion = () => {
        const { toggleModoEdicion } = this.props;
        toggleModoEdicion(false);
        this.setState({
            ...this.state,
            accion: false,
            openVerCotizacion: false,
            openCrearCotizacion: false,
            confirmacion: false
        });
    };

    //FUNCTIONES VISTA SOLICITUDES
    //Submit del formulario de busqueda
    handleSubmit = async data => {
        this.setState({ ...this.state, mostrarBusqueda: true });
        const {
            fetchSolicitudesUsuario,
            fetchItemsTodos,
            fetchSolicitudesAprobar,
            fetchSolicitudes,
            usuarioAutenticado
        } = this.props;
        try {
            // await fetchItemsTodos();
            await fetchSolicitudes();
            await fetchSolicitudesUsuario(
                usuarioAutenticado.data.id,
                data.gerencia ? data.gerencia : '',
                data.estado ? data.estado : '',
                data.proyecto ? data.proyecto : '',
                data.fechaDesde ? data.fechaDesde : '',
                data.fechaHasta ? data.fechaHasta : ''
            );
            await fetchSolicitudesAprobar(
                usuarioAutenticado.data.id,
                data.gerencia ? data.gerencia : '',
                data.estado ? data.estado : '',
                data.proyecto ? data.proyecto : '',
                data.fechaDesde ? data.fechaDesde : '',
                data.fechaHasta ? data.fechaHasta : ''
            );
        } catch (error) {
            console.log(error);
        }
    };

    //Funcion para regresar al tablero de solicitudes
    handleRegresar = e => {
        this.setState({
            ...this.state,
            loadingDetalle: true,
            mostrarBusqueda: true,
            mostrarFormulario: true,
            mostrarDetalle: false
        });
    };

    //Funcion para ir a la vista de detalle de la solicitud
    handleDetalle = id => e => {
        const { history } = this.props;
        history.push(`/detalle/${id}`);
        /*this.setState({ ...this.state, loadingDetalle: true, mostrarBusqueda: false, mostrarFormulario: false, mostrarDetalle: true }, async () => {
            const { assignIdsolicitud, fetchSolicitudes, fetchGruposCotizacion, fetchCotizaciones, fetchItems, fetchAprobador1, fetchAprobador2, usuarioAutenticado } = this.props
            await Promise.all([fetchSolicitudes(), fetchItems(id), fetchAprobador1(id, usuarioAutenticado.data.id), fetchAprobador2(id, usuarioAutenticado.data.id), fetchGruposCotizacion(), fetchCotizaciones()])
            assignIdsolicitud(id)
            this.setState({ ...this.state, loadingDetalle: false })
            
        })*/
    };

    handleActualizar = async e => {
        const {
            fetchSolicitudes,
            fetchSolicitudesAprobar,
            fetchSolicitudesUsuario,
            fetchCotizaciones,
            fetchOrdenesCompra,
            fetchFacturas,
            fetchItemsTodos,
            fetchComentarios,
            usuarioAutenticado,
            form
        } = this.props;
        // await Promise.all([
            fetchSolicitudes(usuarioAutenticado.data.id, 'abiertas');
            fetchSolicitudesUsuario(usuarioAutenticado.data.id, '', '', '', '', '');
            fetchSolicitudesAprobar(usuarioAutenticado.data.id, '', '', '', '', '');
            fetchCotizaciones();
            fetchOrdenesCompra();
            fetchFacturas();
            // fetchItemsTodos();
            fetchComentarios();
        // ]);
    };

    // handleChangeTab = newTab => {
    //     const { changeTab } = this.props;
    //     changeTab(newTab);
    // };

    handleRowClick = (rowData, tab) => {
        console.log('click fila: ', tab);
        const { history, changeTab } = this.props;
        changeTab(tab);
        history.push(`/detalle/${rowData.idSolicitud}`);
    };

    handleLoadingSolicitud = isLoading => {
        this.setState({ ...this.state, loadingSolicitud: isLoading, detalleVisible: true });
    };

    handleCierreDetalle = () => {
        this.setState({ ...this.state, detalleVisible: false });
    }

    handleVerDocumento = (idArchivo, nombre) => {
        var request =
            'https://www.googleapis.com/drive/v3/files/' +
            idArchivo +
            '?alt=media';

        var newTab = window.open(window.location.href + '/waiting', '_blank');
        downloadUrlAsPromise(request, nombre)
            .then(function(response) {
                // console.log('tipo de archivo: ', response.blob.type);
                var tipoGrupo = response.blob.type ? response.blob.type.split('/')[0] : '';
                if (tipoGrupo == 'image' || response.blob.type == 'application/pdf') {
                    var blobUrl = URL.createObjectURL(response.blob);

                    newTab.location = blobUrl;
                } else {
                    newTab.close();
                    saveAs(response.blob, nombre);
                }
            })
            .catch(function(error) {
                console.log(error);
            });
    };

    handleCotizacionModal = async (idCotFila, idSolFila) => {
        // console.log(idCotFila.toString(), idSolFila);
        this.setState({ confirmacion: false, openVerCotizacion: true, accion: false, idSolicitud: idSolFila, idCotizacion: idCotFila });
    };

    // changeFileExcel = e => {
    //     this.setState({ ...this.state, fileExcel: e.target.files[0]});
    // }

    // leerExcelFile = async () => {
    //     const { solicitudesUsuario, editarItems, editarSolicitudes } = this.props;
    //     let file = this.state.fileExcel;
    //     // console.log("archivo excel: ", file);
    //     if (file) {
    //         const wb = new ExcelJS.Workbook();
    //         const reader = new FileReader();

    //         reader.readAsArrayBuffer(file);
    //         reader.onload = () => {
    //             const buffer = reader.result;
    //             wb.xlsx.load(buffer).then(workbook => {
    //                 const worksheet = workbook.getWorksheet('Solicitudes');
    //                 // console.log("el sheet: ", worksheet);
    //                 let cant = 0;
    //                 worksheet.eachRow(async (row, rowNumber) => {
    //                     if (rowNumber > 1 && rowNumber < 450 && !row.hidden) {
    //                         cant++;
    //                         let dataRow = row.values;
    //                         let solFila = solicitudesUsuario.data.filter(x => x.codigo == dataRow[1])[0];
    //                         if (solicitudesUsuario.data.filter(x => x.codigo == dataRow[1])[1]) console.log("solicitud repetida", solFila);
    //                         // console.log("estado: ", solFila.codigo);
    //                         // console.log("fila " + rowNumber, dataRow[1], solFila.codigo);
    //                         solFila.items.forEach(async function (itemSol) {
    //                             // console.log("ítem fila: ", itemSol);
    //                             if (itemSol.estado.id != 20 && itemSol.estado.id != 4) {
    //                                 console.log("actualizando item distinto a entregado", itemSol);
    //                                 // await editarItems(itemSol.id, {
    //                                 //     estado: 20,
    //                                 // });
    //                             }
    //                         });
    //                         if (solFila.estado != 20) {
    //                             console.log("actualizando solicitud distinta a entregada");
    //                             // await editarSolicitudes(solFila.id, {
    //                             //     estado: 20,
    //                             //     fecha_termino: moment().format('YYYY-MM-DD HH:mm:ss')
    //                             // });
    //                         }
    //                     }
    //                 });
    //                 console.log("fiiiiiin", cant);
    //             });
    //         }
            
    //     }
    // }

    render() {
        const {
            selectedTab,
            permisoPruebas,
            permisoEvaluarCosto,
            columnasMisPendientes,
            columnasEnCurso,
            columnasMisSolicitudes,
            columnasVerTodas,
            setColumnConfig,
            usuarioAutenticado,
            classes,
            permisosUsuario
        } = this.props;
        const { loadingDetalle, mostrarBusqueda, mostrarDetalle, mostrarFormulario } = this.state;
        // console.log("classes: ", classes);

        return (
            <Box>
                {permisosUsuario.data.some(x => x.nombre === 'indicadoresLogisticos') && (
                    <Grid container justify="center" alignItems="center" spacing={4} style={{ marginTop: 10 }}>
                        <Grid item xs>
                            <Paper variant="outlined" className={classes.kpiContainer}>
                                <Grid container justify="center" alignItems="center" style={{ position: 'relative' }}>
                                    <div style={{ width: 45, position: 'absolute', backgroundColor: 'white' }}>
                                        <img
                                            src={ImagenStock}
                                            style={{ width: 40, margin: 'auto', display: 'block' }}
                                        />
                                    </div>
                                </Grid>
                                <Typography gutterBottom variant="h5" component="p" className={classes.kpiValue}>
                                    {this.state.indicadoresEstado?.revStock || '-'}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    component="p"
                                    className={classes.kpiTitle}>
                                    Revisión Stock
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs>
                            <Paper variant="outlined" className={classes.kpiContainer}>
                                <Grid container justify="center" alignItems="center" style={{ position: 'relative' }}>
                                    <div style={{ width: 40, position: 'absolute', backgroundColor: 'white' }}>
                                        <img src={ImagenCot} style={{ width: 35, margin: 'auto', display: 'block' }} />
                                    </div>
                                </Grid>
                                <Typography gutterBottom variant="h5" component="p" className={classes.kpiValue}>
                                    {this.state.indicadoresEstado?.cotizacion || '-'}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    component="p"
                                    className={classes.kpiTitle}>
                                    Cotización
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs>
                            <Paper variant="outlined" className={classes.kpiContainer}>
                                <Grid container justify="center" alignItems="center" style={{ position: 'relative' }}>
                                    <div style={{ width: 45, position: 'absolute', backgroundColor: 'white' }}>
                                        <img
                                            src={ImagenOCAprob}
                                            style={{ width: 40, margin: 'auto', display: 'block' }}
                                        />
                                    </div>
                                </Grid>
                                <Typography gutterBottom variant="h5" component="p" className={classes.kpiValue}>
                                    {this.state.indicadoresEstado?.aprobOC || '-'}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    component="p"
                                    className={classes.kpiTitle}>
                                    Aprobación OC
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs>
                            <Paper variant="outlined" className={classes.kpiContainer}>
                                <Grid container justify="center" alignItems="center" style={{ position: 'relative' }}>
                                    <div style={{ width: 45, position: 'absolute', backgroundColor: 'white' }}>
                                        <img
                                            src={ImagenRecepcion}
                                            style={{ width: 40, margin: 'auto', display: 'block' }}
                                        />
                                    </div>
                                </Grid>
                                <Typography gutterBottom variant="h5" component="p" className={classes.kpiValue}>
                                    {this.state.indicadoresEstado?.recepcionOC || '-'}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    component="p"
                                    className={classes.kpiTitle}>
                                    Recepción Proveedor
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs>
                            <Paper variant="outlined" className={classes.kpiContainer}>
                                <Grid container justify="center" alignItems="center" style={{ position: 'relative' }}>
                                    <div style={{ width: 45, position: 'absolute', backgroundColor: 'white' }}>
                                        <img
                                            src={ImagenPreparar}
                                            style={{ width: 40, margin: 'auto', display: 'block' }}
                                        />
                                    </div>
                                </Grid>
                                <Typography gutterBottom variant="h5" component="p" className={classes.kpiValue}>
                                    {this.state.indicadoresEstado?.preparacion || '-'}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    component="p"
                                    className={classes.kpiTitle}>
                                    Preparar Recurso
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs>
                            <Paper variant="outlined" className={classes.kpiContainer}>
                                <Grid container justify="center" alignItems="center" style={{ position: 'relative' }}>
                                    <div style={{ width: 75, position: 'absolute', backgroundColor: 'white' }}>
                                        <img
                                            src={ImagenTraslado}
                                            style={{ width: 70, margin: 'auto', display: 'block' }}
                                        />
                                    </div>
                                </Grid>
                                <Typography gutterBottom variant="h5" component="p" className={classes.kpiValue}>
                                    {this.state.indicadoresEstado?.traslado || '-'}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    component="p"
                                    className={classes.kpiTitle}>
                                    En Traslado
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs>
                            <Paper variant="outlined" className={classes.kpiContainer}>
                                <Grid container justify="center" alignItems="center" style={{ position: 'relative' }}>
                                    <div style={{ width: 45, position: 'absolute', backgroundColor: 'white' }}>
                                        <img
                                            src={ImagenListoEntrega}
                                            style={{ width: 40, margin: 'auto', display: 'block' }}
                                        />
                                    </div>
                                </Grid>
                                <Typography gutterBottom variant="h5" component="p" className={classes.kpiValue}>
                                    {this.state.indicadoresEstado?.listoEntrega || '-'}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    component="p"
                                    className={classes.kpiTitle}>
                                    Listo para Entregar
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs>
                            <Paper
                                variant="outlined"
                                className={classes.kpiContainer}
                                style={{ cursor: permisoEvaluarCosto ? 'pointer' : 'inherit' }}
                                onClick={() => {
                                    if (permisoEvaluarCosto) this.setState({ openEvalCostoPendientes: true });
                                }}>
                                <Grid container justify="center" alignItems="center" style={{ position: 'relative' }}>
                                    <div style={{ width: 45, position: 'absolute', backgroundColor: 'white' }}>
                                        <img
                                            src={ImagenEvalCosto}
                                            style={{ width: 40, margin: 'auto', display: 'block' }}
                                        />
                                    </div>
                                </Grid>
                                <Typography gutterBottom variant="h5" component="p" className={classes.kpiValue}>
                                    {this.state.indicadoresEstado?.evalCosto?.length || '-'}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    component="p"
                                    className={classes.kpiTitle}>
                                    Evaluación de Costos
                                </Typography>
                            </Paper>
                        </Grid>
                    </Grid>
                )}
                {/* <input type="file" onChange={this.changeFileExcel} />
                <Button variant="outlined" color="primary" onClick={this.leerExcelFile}>Leer excel</Button> */}
                {mostrarBusqueda && (
                    <Grid container>
                        <Grid item xs={12} lg={12}>
                            <Tabs
                                selectedTab={selectedTab}
                                permisoPruebas={permisoPruebas}
                                permisoEvaluarCosto={permisoEvaluarCosto}
                                columnasMisPendientes={columnasMisPendientes}
                                columnasEnCurso={columnasEnCurso}
                                columnasMisSolicitudes={columnasMisSolicitudes}
                                columnasVerTodas={columnasVerTodas}
                                setColumnConfig={setColumnConfig}
                                handleActualizar={this.handleActualizar}
                                handleRowClick={this.handleRowClick}
                                usuarioAutenticado={usuarioAutenticado}
                                handleLoadingSolicitud={this.handleLoadingSolicitud}
                                handleCierreDetalle={this.handleCierreDetalle}
                                handleVerDocumento={this.handleVerDocumento}
                                handleCotizacionModal={this.handleCotizacionModal}
                                cargarIndicadores={this.cargarIndicadores}
                            />
                        </Grid>
                    </Grid>
                )}

                {/* {mostrarDetalle && (
                    <Box>
                        <Grid>
                            <Typography align="center" variant="h4" gutterBottom>
                                Detalle del Proceso
                            </Typography>
                        </Grid>
                        <Grid>
                            <Typography align="center" variant="body1" gutterBottom>
                                <Tooltip title="Regresar a solicitudes">
                                    <IconButton onClick={this.handleRegresar}>
                                        <i className="material-icons">keyboard_arrow_left</i>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Actualizar">
                                    <IconButton onClick={this.handleDetalle(idSolicitud)}>
                                        <i className="material-icons">autorenew</i>
                                    </IconButton>
                                </Tooltip>
                            </Typography>
                        </Grid>
                        {loadingDetalle ? (
                            <Spinner />
                        ) : (
                            <Detalle estados={estados} items={items} solicitud={solicitudActual} />
                        )}
                    </Box>
                )} */}
                <Modal
                    // title="Detalle Cotización"
                    showTitle={false}
                    xl={true}
                    fullWidth={true}
                    style={{ backgroundColor: '#f5f5f5' }}
                    open={this.state.openVerCotizacion}
                    handleClose={this.handleCloseCotizacion}
                    confirmacion={this.state.confirmacion}
                    accion={this.state.accion}
                    loading={false}>
                    {
                        <VerCotizacion
                            idSolicitud={this.state.idSolicitud}
                            idCotizacion={this.state.idCotizacion}
                            handleVerDocumento={this.handleVerDocumento}
                            cargarIndicadores={this.cargarIndicadores}
                            usuarioAutenticado={usuarioAutenticado}
                            permisoEvaluarCosto={permisoEvaluarCosto}
                        />
                    }
                </Modal>
                <Modal
                    title="Evaluaciones de Costos Pendientes"
                    // showTitle={false}
                    maxWidth="md"
                    fullWidth={true}
                    style={{ backgroundColor: '#f5f5f5' }}
                    open={this.state.openEvalCostoPendientes}
                    handleClose={() => {
                        this.setState({ openEvalCostoPendientes: false });
                    }}
                    confirmacion={false}
                    accion={false}>
                    <TableContainer component={Paper} style={{}}>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Solicitud</TableCell>
                                    <TableCell>Cotización</TableCell>
                                    <TableCell>Ver</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.indicadoresEstado?.evalCosto
                                    ?.map((x, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{x.solicitud.codigo}</TableCell>
                                            <TableCell>{x.codigo}</TableCell>
                                            <TableCell>
                                                <Tooltip title="Ver Cotización">
                                                    <IconButton
                                                        color="primary"
                                                        size="small"
                                                        onClick={() => {
                                                            console.log('viendo cotizacion', x.oldId, x.solicitud_recurso_id);
                                                            this.setState({
                                                                confirmacion: false,
                                                                openVerCotizacion: true,
                                                                accion: false,
                                                                idSolicitud: x.solicitud_recurso_id,
                                                                idCotizacion: x.oldId,
                                                            });
                                                        }}>
                                                        <InsertDriveFileOutlinedIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Modal>
            </Box>
        );
    }
}

const mapStateToProps = state => {
    // console.log("sol todas: ", state.Solicitudes);
    // console.log("sol usuarios: ", state.SolicitudesUsuario);
    // console.log("sol pendientes: ", state.SolicitudesAprobar);
    return {
        selectedTab: state.GeneralOptions.selectedTab,
        permisosUsuario: state.PermisosUsuario,
        permisoPruebas: state.PermisosUsuario.data.some(x => x.nombre === 'usuarioPruebas'),
        permisoEvaluarCosto: state.PermisosUsuario.data.some(x => x.nombre === 'evaluarCostoProveedor'),
        columnasMisPendientes: state.GeneralOptions.columnasMisPendientes,
        columnasEnCurso: state.GeneralOptions.columnasEnCurso,
        columnasMisSolicitudes: state.GeneralOptions.columnasMisSolicitudes,
        columnasVerTodas: state.GeneralOptions.columnasVerTodas,
        usuarioAutenticado: state.Usuario,
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            ...solicitudesUsuarioDuck,
            ...solicitudesAprobarDuck,
            ...solicitudesDuck,
            ...idsolicitudDuck,
            ...iditemDuck,
            ...itemsDuck,
            ...itemsAllDuck,
            ...aprobador1Duck,
            ...aprobador2Duck,
            ...vehiculoDuck,
            ...mensajesDuck,
            ...modoEdicionDuck,
            ...gruposCotizacionDuck,
            ...cotizacionesDuck,
            ...idGrupoCotizacionDuck,
            ...itemsCotizacionDuck,
            ...archivoDuck,
            ...itemsCotizadosDuck,
            ...proyectosGeneralDuck,
            ...gerenciasGeneralDuck,
            ...comprobanteRecepcionDuck,
            ...entregaItemRecurso,
            ...trasladoItemRecursoDuck,
            ...generalOptions,
            ...ordenesCompraDuck,
            ...facturasDuck,
            ...comentariosDuck,
            ...aprobadoresGerenciaDuck,
            // ...configuracionesDuck,
            ...aprobadoresCotizacionDuck
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Solicitudes));
