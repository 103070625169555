import React, { useState, useEffect } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Grid, Container, MenuItem, Button, Typography, Radio, RadioGroup, FormControlLabel } from '@material-ui/core';
import { connect } from 'react-redux';
import InputAdornment from '@material-ui/core/InputAdornment';
import FieldFiledInput from './FieldFileInput';
import SelectField from './SelectField';
import SelectSearch from './SelectSearch';
import Checkbox from './Checkbox';
import KeyboardDatePicker from './KeyboardDatePicker';
// import FieldTextField from './FieldTextField';
import TextField from '../components/TextField';
import { currency } from '../services/utils';
import { getCurrentUser } from '../services/auth';
import moment from 'moment';
import 'moment/locale/es';
moment.locale('es');

const validate = (values = {}) => {
    const errors = {};
    const requiredFields = [
        'tipo_doc',
        'numero',
        'monto',
        'fecha_doc',
        'fecha',
        'file',
        'calidad1',
        'calidad2',
        'calidad3',
        'calidad4',
        'calidad5',
        'calidad6',
        'calidad7',
        'estado_pago',
        'clasificacion_factura',
        'iva',
        'descripcion'
    ];
    // if (values['tipo_doc'] == 'Factura') requiredFields.push('estado_pago');
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = true;
        }
    });
    if (values.monto == "0") errors['monto'] = true;
    if (values['dias_vencimiento'] != 0 && values['dias_vencimiento'] != 15 && values['dias_vencimiento'] != 30 && values['dias_vencimiento'] != 45) errors['dias_vencimiento'] = true;
    // if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    //     errors.email = 'Invalid email address';
    // }
    return errors;
};

const RadioGroupField = ({ input, meta: { touched, error }, children, ...custom }) => (
    <RadioGroup
        row
        value={input.value}
        onChange={input.onChange}
        style={{ color: touched && error ? '#ff0000' : '#000000de', margin: '5px 0' }}>
        <FormControlLabel style={{ marginBottom: 0 }} value="1" control={<Radio color="primary" />} label="Sí" />
        <FormControlLabel style={{ marginBottom: 0 }} value="0" control={<Radio color="primary" />} label="No" />
        <FormControlLabel style={{ marginBottom: 0 }} value="2" control={<Radio color="primary" />} label="N/A" />
    </RadioGroup>
);

let FormItemRecibido = props => {
    const { handleSubmit, handleOnChangeFileComprobante, fileName, formRecibir, tipoRecepcion, change, touch, todoRecibido, dataCotizacion } = props;
    const [clasificacionFactura, setClasificacionFactura] = useState([]);
    // console.log("estado RUT", estadoRUT);

    useEffect(() => {
        let fetchData = async () => {
            let token = await getCurrentUser();
            let clasif = await fetch('https://api-rendiciones.cydingenieria.com/clasificaciones-facturas', {
                method: 'GET',
                headers: {
                    Authorization: 'Bearer ' + token.token
                },
                // body: JSON.stringify(values)
            });
            let dataClasif = await clasif.json();
            setClasificacionFactura([...dataClasif.data]);
        }
        fetchData();
    }, []);
    
    let opcionesEstadoPago =
        ['Anticipo', 'Pago Online'].indexOf(dataCotizacion.condicion_pago) > -1
            ? [
                  { nombre: 'Pago Inmediato Realizado', valor: 'Pago inmediato' },
                  { nombre: 'Orden de pago directo a proveedor', valor: 'Procesando Pago' },
                  { nombre: 'Pagado', valor: 'Pagado' }
              ]
            : [
                  { nombre: 'Orden de pago directo a proveedor', valor: 'Procesando Pago' },
                  { nombre: 'Pagado', valor: 'Pagado' }
              ];

    return (
        <Container>
            <form noValidate onSubmit={handleSubmit}>
                {/* {tipoRecepcion != 'todos' && (
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={6}>
                            <Grid container justify="center" alignItems="center">
                                <Field
                                    style={{ width: '90%' }}
                                    label="Items Recepcionados"
                                    name="entregados"
                                    component={TextField}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    InputProps={{
                                        disabled: true
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Grid container justify="center" alignItems="center">
                                <Field
                                    style={{ width: '90%' }}
                                    type="number"
                                    name="cantidad"
                                    label="Items a Recepcionar"
                                    component={TextField}
                                    //normalize={numero}
                                    // validate={[maxValue]}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                )} */}
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={6}>
                        <Grid container justify="center" alignItems="center">
                            <Field
                                required
                                style={{ width: '95%' }}
                                name="tipo_doc"
                                label="Tipo"
                                component={SelectField}>
                                {[
                                    <MenuItem key="" value="">
                                        Seleccionar
                                    </MenuItem>
                                ].concat(
                                    ['Guía Despacho', 'Factura'].map((x, id) => (
                                        <MenuItem key={id} value={x}>
                                            {x}
                                        </MenuItem>
                                    ))
                                )}
                            </Field>
                        </Grid>
                    </Grid>
                    {formRecibir?.values?.tipo_doc === 'Factura' && (
                        <Grid item xs={12} lg={6}>
                            <Grid container justify="center" alignItems="center">
                                <Field
                                    required
                                    style={{ width: '95%' }}
                                    disabled={['Anticipo', 'Pago Online'].indexOf(dataCotizacion.condicion_pago) > -1}
                                    name="estado_pago"
                                    label="Estado del pago"
                                    component={SelectField}>
                                    {[
                                        <MenuItem key="" value="">
                                            Seleccionar
                                        </MenuItem>
                                    ].concat(
                                        opcionesEstadoPago.map((x, id) => (
                                            <MenuItem key={id} value={x.valor}>
                                                {x.nombre}
                                            </MenuItem>
                                        ))
                                    )}
                                </Field>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
                {formRecibir?.values?.tipo_doc && (
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={6}>
                            <Grid container justify="center" alignItems="center">
                                <Field
                                    required
                                    style={{ width: '95%' }}
                                    name="fecha_doc"
                                    label="Fecha Documento *"
                                    change={change}
                                    component={KeyboardDatePicker}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Grid container justify="center" alignItems="center">
                                <Field
                                    required
                                    style={{ width: '95%' }}
                                    name="fecha"
                                    label="Fecha Recepción *"
                                    change={change}
                                    // format="DD/MM/YYYY"
                                    component={KeyboardDatePicker}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                )}
                {formRecibir?.values?.tipo_doc === 'Factura' && formRecibir?.values?.estado_pago == 'Procesando Pago' && (
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={6}>
                            <Grid container justify="center" alignItems="center">
                                <Field
                                    required
                                    style={{ width: '95%' }}
                                    name="clasificacion_factura"
                                    label="Clasificación Factura"
                                    type="text"
                                    change={change}
                                    touch={touch}
                                    options={clasificacionFactura}
                                    component={SelectSearch}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Grid container justify="center" alignItems="center">
                                <Field
                                    required
                                    style={{ width: '95%' }}
                                    name="dias_vencimiento"
                                    label="Plazo Pago"
                                    component={SelectField}>
                                    {[
                                        <MenuItem key="" value="">
                                            Seleccionar
                                        </MenuItem>
                                    ].concat(
                                        [
                                            { nombre: 'Contado', valor: 0 },
                                            { nombre: 'A 15 días', valor: 15 },
                                            { nombre: 'A 30 días', valor: 30 },
                                            { nombre: 'A 45 días', valor: 45 }
                                        ].map((x, id) => (
                                            <MenuItem key={id} value={x.valor}>
                                                {x.nombre}
                                            </MenuItem>
                                        ))
                                    )}
                                </Field>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
                {formRecibir?.values?.tipo_doc && (
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={6}>
                            <Grid container justify="center" alignItems="center">
                                <Field
                                    required
                                    style={{ width: '95%' }}
                                    type="number"
                                    name="numero"
                                    label="Número Documento"
                                    component={TextField}
                                />
                            </Grid>
                        </Grid>
                        {formRecibir?.values?.tipo_doc === 'Factura' && (
                            <Grid item xs={12} lg={3}>
                                <Grid container justify="center" alignItems="center">
                                    <Field
                                        required
                                        style={{ width: '90%' }}
                                        format={currency}
                                        // type="text"
                                        name="monto"
                                        label="Total Factura"
                                        component={TextField}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">$</InputAdornment>
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        )}
                        {formRecibir?.values?.tipo_doc === 'Factura' && (
                            <Grid item xs={12} lg={3}>
                                <Grid container justify="center" alignItems="center">
                                    <Field
                                        required
                                        style={{ width: '90%' }}
                                        format={currency}
                                        // type="text"
                                        name="iva"
                                        label="IVA Factura"
                                        component={TextField}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">$</InputAdornment>
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                )}
                {formRecibir?.values?.tipo_doc === 'Factura' && formRecibir?.values?.estado_pago == 'Procesando Pago' && (
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={6}>
                            <Grid container justify="center" alignItems="center">
                                <Field
                                    required
                                    multiline
                                    type="text"
                                    name="descripcion"
                                    rows="2"
                                    characterLimit={200}
                                    inputProps={{
                                        maxLength: '200'
                                    }}
                                    style={{ width: '95%' }}
                                    label="Descripción"
                                    component={TextField}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Grid container justify="center" alignItems="center">
                                <Field
                                    multiline
                                    type="text"
                                    name="comentario"
                                    rows="2"
                                    characterLimit={200}
                                    inputProps={{
                                        maxLength: '200'
                                    }}
                                    style={{ width: '95%' }}
                                    label="Comentario (opcional)"
                                    component={TextField}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                )}
                {formRecibir?.values?.tipo_doc && (
                    <Grid container style={{ marginTop: '20px' }} justify="center" alignItems="center">
                        <Grid item xs={12} lg={12}>
                            <Grid container justify="center" alignItems="center">
                                <Field
                                    required
                                    style={{ width: '95%' }}
                                    name="file"
                                    component={FieldFiledInput}
                                    label="Archivo Comprobante"
                                    accept=""
                                    type="file"
                                    fileName={fileName}
                                    handleOnChange={handleOnChangeFileComprobante}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                )}
                {todoRecibido && (
                    <div style={{ marginTop: 30 }}>
                        <Typography variant="h6" gutterBottom>Evaluación de Calidad</Typography>
                        {/* <Typography variant="body2">Marque si se cumplieron los siguientes</Typography> */}
                        <Grid container spacing={3}>
                            <Grid item xs={12} lg={12}>
                                <Grid container justify="flex-start" alignItems="center">
                                    {/* <Field
                                        name="calidad1"
                                        component={Checkbox}
                                        label="¿Los recursos fueron entregados junto a guía o factura?"
                                    /> */}
                                    <Field name="calidad1" component={RadioGroupField} />
                                    <Typography variant="body1" style={{ maxWidth: 330 }}>
                                        ¿Los recursos fueron entregados junto a guía o factura?
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} lg={12}>
                                <Grid container justify="flex-start" alignItems="center">
                                    {/* <Field
                                        name="calidad2"
                                        component={Checkbox}
                                        label="¿Proveedor entrega bultos correctamente embalados y rotulados?"
                                    /> */}
                                    <Field name="calidad2" component={RadioGroupField} />
                                    <Typography variant="body1" style={{ maxWidth: 330 }}>
                                        ¿Proveedor entrega bultos correctamente embalados y rotulados?
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} lg={12}>
                                <Grid container justify="flex-start" alignItems="center">
                                    {/* <Field
                                        name="calidad3"
                                        component={Checkbox}
                                        label="¿La cantidad recibida corresponde a la indicada en guía o factura?"
                                    /> */}
                                    <Field name="calidad3" component={RadioGroupField} />
                                    <Typography variant="body1" style={{ maxWidth: 330 }}>
                                        ¿La cantidad recibida corresponde a la indicada en guía o factura?
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} lg={12}>
                                <Grid container justify="flex-start" alignItems="center">
                                    {/* <Field
                                        name="calidad4"
                                        component={Checkbox}
                                        label="¿Los recursos fueron entregados sin defectos o daños?"
                                    /> */}
                                    <Field name="calidad4" component={RadioGroupField} />
                                    <Typography variant="body1" style={{ maxWidth: 330 }}>
                                        ¿Los recursos fueron entregados sin defectos o daños?
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} lg={12}>
                                <Grid container justify="flex-start" alignItems="center">
                                    {/* <Field
                                        name="calidad5"
                                        component={Checkbox}
                                        label="¿La cantidad entregada cumple con la cantidad solicitada en OC?"
                                    /> */}
                                    <Field name="calidad5" component={RadioGroupField} />
                                    <Typography variant="body1" style={{ maxWidth: 330 }}>
                                        ¿La cantidad entregada cumple con la cantidad solicitada en OC?
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} lg={12}>
                                <Grid container justify="flex-start" alignItems="center">
                                    {/* <Field
                                        name="calidad6"
                                        component={Checkbox}
                                        label="¿Los recursos corresponden a lo solicitado?"
                                    /> */}
                                    <Field name="calidad6" component={RadioGroupField} />
                                    <Typography variant="body1" style={{ maxWidth: 330 }}>
                                        ¿Los recursos corresponden a lo solicitado?
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} lg={12}>
                                <Grid container justify="flex-start" alignItems="center">
                                    {/* <Field
                                        name="calidad7"
                                        component={Checkbox}
                                        label="¿El precio o monto facturado, corresponde a lo indicado en cotización/orden de compra?"
                                    /> */}
                                    <Field name="calidad7" component={RadioGroupField} />
                                    <Typography variant="body1" style={{ maxWidth: 330 }}>
                                        ¿El precio o monto facturado, corresponde a lo indicado en cotización/orden de compra?
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                )}
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={12}>
                        <Grid container style={{ marginTop: '20px' }} justify="center" alignItems="center">
                            <Button type="submit" variant="outlined" color="primary">
                                Recepcionar
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </Container>
    );
};

FormItemRecibido = reduxForm({
    form: 'recibirItems'
})(FormItemRecibido);

export default connect((state, props) => ({
    initialValues: {
        file: '',
        fecha: null,
        fecha_doc: null,
        // fecha_vencimiento: moment().format('DD/MM/YYYY'),
        numero: '',
        monto: '0',
        iva: '0',
        descripcion: props.descripcion,
        comentario: '',
        estado_pago:
            ['Anticipo', 'Pago Online'].indexOf(props?.dataCotizacion?.condicion_pago) > -1
                ? 'Pago inmediato'
                : null
        // cantidad: 1,
        // max: props.max,
        // entregados: props.entregados
    },
    enableReinitialize: true,
    validate
}))(FormItemRecibido);
