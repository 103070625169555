import React, { useState, useEffect } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Grid, Container, MenuItem, Button } from '@material-ui/core';
import { connect } from 'react-redux';
import InputAdornment from '@material-ui/core/InputAdornment';
import FieldFiledInput from '../components/FieldFileInput';
import KeyboardDatePicker from '../components/KeyboardDatePicker';
import TextField from '../components/TextField';
import SelectSearch from '../components/SelectSearch';
import SelectField from '../components/SelectField';
import { currency } from '../services/utils';
import { getCurrentUser } from '../services/auth';
import moment from 'moment';
import 'moment/locale/es';
moment.locale('es');

const validate = (values = {}) => {
    const errors = {};
    const requiredFields = [
        'orden_compra',
        'fecha',
        'numero',
        'monto',
        'file',
        'estado_pago',
        'clasificacion_factura',
        'iva',
        'descripcion'
    ];
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = true;
        }
    });
    if (values.monto == "0") errors['monto'] = true;
    if (values['dias_vencimiento'] != 0 && values['dias_vencimiento'] != 15 && values['dias_vencimiento'] != 30 && values['dias_vencimiento'] != 45) errors['dias_vencimiento'] = true;
    if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Invalid email address';
    }
    return errors;
};

let FormCargarFactura = props => {
    const {
        ordenesCompra,
        handleSubmit,
        handleOnChangeFileFactura,
        fileName,
        change,
        touch,
        formCargar,
        cotizaciones,
        items,
    } = props;
    const [clasificacionFactura, setClasificacionFactura] = useState([]);

    useEffect(() => {
        let fetchData = async () => {
            let token = await getCurrentUser();
            let clasif = await fetch('https://api-rendiciones.cydingenieria.com/clasificaciones-facturas', {
                method: 'GET',
                headers: {
                    Authorization: 'Bearer ' + token.token
                },
                // body: JSON.stringify(values)
            });
            let dataClasif = await clasif.json();
            setClasificacionFactura([...dataClasif.data]);
        }
        fetchData();
    }, []);

    const handleChangeOC = async (name, newValue) => {
        change(name, newValue);
        let newDescr = 'Compra de ';
        if (ordenesCompra.find(x => x.id === newValue)) {
            let clasesNom = [];
            let cotizacion = cotizaciones.find(x => x.ordenCompra?.id === newValue);
            cotizacion.item_cotizacion.forEach(x => {
                let itemSol = items.find(y => y.id === x.item_recurso_id);
                if (clasesNom.indexOf(itemSol.claseRef.nombre) == -1)
                    clasesNom.push(itemSol.claseRef.nombre);
            });
            newDescr += clasesNom.join(', ');
        }
        change('descripcion', newDescr);
    }

    return (
        <Container>
            <form noValidate onSubmit={handleSubmit}>
                <Grid container>
                    <Grid item xs={12} lg={6}>
                        <Grid container justify="center" alignItems="center">
                            <Field
                                required
                                style={{ width: '95%' }}
                                type="text"
                                change={handleChangeOC}
                                touch={touch}
                                name="orden_compra"
                                label="Órden de Compra"
                                options={ordenesCompra}
                                component={SelectSearch}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Grid container justify="center" alignItems="center">
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} lg={6}>
                        <Grid container justify="center" alignItems="center">
                            <Field
                                required
                                style={{ width: '95%' }}
                                name="fecha"
                                label="Fecha Factura"
                                change={change}
                                component={KeyboardDatePicker}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Grid container justify="center" alignItems="center">
                            <Field
                                required
                                style={{ width: '95%' }}
                                name="estado_pago"
                                label="Estado del pago"
                                component={SelectField}>
                                {[
                                    <MenuItem key="" value="">
                                        Seleccionar
                                    </MenuItem>
                                ].concat(
                                    [
                                        { nombre: 'Orden de pago directo a proveedor', valor: 'Procesando Pago' },
                                        { nombre: 'Pagado (Rendir en Gestor de Pagos)', valor: 'Pagado' }
                                    ].map((x, id) => (
                                        <MenuItem key={id} value={x.valor}>
                                            {x.nombre}
                                        </MenuItem>
                                    ))
                                )}
                            </Field>
                        </Grid>
                    </Grid>
                </Grid>
                {formCargar?.values?.estado_pago == 'Procesando Pago' && (
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={6}>
                            <Grid container justify="center" alignItems="center">
                                <Field
                                    required
                                    style={{ width: '95%' }}
                                    name="clasificacion_factura"
                                    label="Clasificación Factura"
                                    type="text"
                                    change={change}
                                    touch={touch}
                                    options={clasificacionFactura}
                                    component={SelectSearch}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Grid container justify="center" alignItems="center">
                                <Field
                                    required
                                    style={{ width: '95%' }}
                                    name="dias_vencimiento"
                                    label="Plazo Pago"
                                    component={SelectField}>
                                    {[
                                        <MenuItem key="" value="">
                                            Seleccionar
                                        </MenuItem>
                                    ].concat(
                                        [
                                            { nombre: 'Contado', valor: 0 },
                                            { nombre: 'A 15 días', valor: 15 },
                                            { nombre: 'A 30 días', valor: 30 },
                                            { nombre: 'A 45 días', valor: 45 }
                                        ].map((x, id) => (
                                            <MenuItem key={id} value={x.valor}>
                                                {x.nombre}
                                            </MenuItem>
                                        ))
                                    )}
                                </Field>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
                <Grid container>
                    <Grid item xs={12} lg={6}>
                        <Grid container justify="center" alignItems="center">
                            <Field
                                required
                                style={{ width: '95%' }}
                                type="number"
                                name="numero"
                                label="Número Factura"
                                component={TextField}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <Grid container justify="center" alignItems="center">
                            <Field
                                required
                                style={{ width: '90%' }}
                                format={currency}
                                name="monto"
                                label="Total Factura"
                                component={TextField}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <Grid container justify="center" alignItems="center">
                            <Field
                                required
                                style={{ width: '90%' }}
                                format={currency}
                                // type="text"
                                name="iva"
                                label="IVA Factura"
                                component={TextField}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                {formCargar?.values?.estado_pago == 'Procesando Pago' && (
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={6}>
                            <Grid container justify="center" alignItems="center">
                                <Field
                                    required
                                    multiline
                                    type="text"
                                    name="descripcion"
                                    rows="2"
                                    characterLimit={200}
                                    inputProps={{
                                        maxLength: '200'
                                    }}
                                    style={{ width: '95%' }}
                                    label="Descripción"
                                    component={TextField}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Grid container justify="center" alignItems="center">
                                <Field
                                    multiline
                                    type="text"
                                    name="comentario"
                                    rows="2"
                                    characterLimit={200}
                                    inputProps={{
                                        maxLength: '200'
                                    }}
                                    style={{ width: '95%' }}
                                    label="Comentario (opcional)"
                                    component={TextField}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                )}
                <Grid container>
                    <Grid item xs={12} lg={12} style={{ marginTop: '20px' }}>
                        <Grid container justify="center" alignItems="center">
                            <Field
                                required
                                name="file"
                                component={FieldFiledInput}
                                label="Archivo de Factura"
                                accept=""
                                type="file"
                                fileName={fileName}
                                handleOnChange={handleOnChangeFileFactura}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} lg={12} style={{ marginTop: '20px' }}>
                        <Grid container justify="center" alignItems="center">
                            <Button type="submit" variant="outlined" color="primary">
                                Cargar Factura
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </Container>
    );
};

FormCargarFactura = reduxForm({
    form: 'cargarFactura'
})(FormCargarFactura);

export default connect((state, props) => ({
    initialValues: {
        file: '',
        fecha: null,
        numero: '',
        monto: '0',
        iva: '0',
        descripcion: 'Compra de ',
        comentario: ''
    },
    enableReinitialize: true,
    validate
}))(FormCargarFactura);
