import Axios from "axios";

export const addMillsSep = (num, moneda = "CLP") => {
    var x1 = (moneda == "CLP") ? Math.round(num).toString() : parseFloat(num).toFixed(2);
    // var x1 = num.toString();
    x1 = x1.replace('.', ',');
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + '.' + '$2');
    }
    return x1;
};

export const currency = value => {
    // let stringValue = value && value.replace(/\./g, '');
    // let valor = stringValue && parseFloat(stringValue);
    // valor = valor && valor.toLocaleString('es-CL');
    // return valor ? valor : '0';
    let newValue = value
        .replace(/[^0-9\,]/g, '')
        .replace(/(\d*\,\d*)(\,)(\d*)/g, '$1$3')
        .replace(/^(\d+,?\d{0,2})\d*$/, '$1');
    let partes = newValue.split(',');
    return addMillsSep(partes[0]);
};

export const compareObjectString = (key, order = 'asc') => {
    return (a, b) => {
        if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) return 0;
        const comparison = a[key].localeCompare(b[key]);

        return order === 'desc' ? comparison * -1 : comparison;
    };
};

export const downloadUrlAsPromise = (url, fileName) => {
    try {
        return new Promise(function(resolve, reject) {
            var url2 = 'https://script.google.com/macros/s/AKfycbwfc_339gl3N-1vAzDLIaTHbyrZRXRtOjEqcBibgQp5AyQslHE/exec';
            fetch(url2, {
                method: 'POST'
            }).then(res => res.json())
            .then(result => {
                var xhr = new XMLHttpRequest();
                xhr.open('GET', url);
                xhr.setRequestHeader('Authorization', 'Bearer ' + result.access);
                xhr.responseType = 'blob';
                xhr.onreadystatechange = function(evt) {
                    if (xhr.readyState === 4) {
                        if (xhr.status === 200) {
                            resolve({
                                blob: xhr.response,
                                name: fileName
                            });
                        } else {
                            reject(new Error('Ajax error for ' + url + ': ' + xhr.status));
                        }
                    }
                };
                xhr.send();
            })
            .catch(error => {
                console.log("errorrrr", error)
                reject(error);
            });
        });
    } catch (e) {
        console.log(e);
    }
};

export const uploadFileAsPromise = (file, folderId ) => {
    return new Promise(function (resolve, reject) {
        var fileReader = new FileReader();

        fileReader.onload = function (e) {
            var form = new FormData();
            form.append('file', e.target.result.replace(/^.*,/, ''));
            // form.append('file', e.target.result.split(',')[1]);
            form.append('filename', file.name);
            form.append('folderId', folderId);
            form.append('mimeType', file.type);
            var url = 'https://script.google.com/macros/s/AKfycby1hX1I0b8Hy9rZZofEf4WqcRv7vj6Ze0pnMUyNJbv_HqKUtvM/exec';
            fetch(url, {
                method: 'POST',
                body: form
            }).then(res => res.json())
            .then(result => {
                resolve(result);
            })
            .catch(error => {
                reject(error);
            });
        };
        fileReader.readAsDataURL(file);
    });
};

export const getQueryParams = () => {
    const query = window.location.search.substring(1);
    const vars = query.split('&');
    let queryParams = {};
    for (let i = 0; i < vars.length; i++) {
        let pair = vars[i].split('=');
        if (pair.length == 2) queryParams[pair[0]] = pair[1];
    }
    return queryParams;
};


export const checkRut = (rut = '') => {
    if (rut == null) rut = '';
    // Despejar Puntos
    var valor = rut.replace(/\./g, '');
    // Despejar Guión
    valor = valor.replace(/\-/g, '');
    // Aislar Cuerpo y Dígito Verificador
    var cuerpo = valor.slice(0, -1);
    var dv = valor.slice(-1).toUpperCase();
    // Formatear RUN
    rut = cuerpo + '-' + dv;

    if (cuerpo.length < 7) {
        return false;
    }

    var suma = 0;
    var multiplo = 2;

    // Para cada dígito del Cuerpo
    for (var i = 1; i <= cuerpo.length; i++) {
        // Obtener su Producto con el Múltiplo Correspondiente
        var index = multiplo * valor.charAt(cuerpo.length - i);
        // Sumar al Contador General
        suma = suma + index;
        // Consolidar Múltiplo dentro del rango [2,7]
        if (multiplo < 7) {
            multiplo = multiplo + 1;
        } else {
            multiplo = 2;
        }
    }

    // Calcular Dígito Verificador en base al Módulo 11
    var dvEsperado = 11 - (suma % 11);

    // Casos Especiales (0 y K)
    dv = dv == 'K' ? 10 : dv;
    dv = dv == 0 ? 11 : dv;

    // Validar que el Cuerpo coincide con su Dígito Verificador
    if (dvEsperado != dv) {
        return false;
    }

    return true;
};

export const formatoRut = (rut) => {
    var textoRut = rut.replace(/[\W\s\._\-a-jA-Jl-zL-Z]+/g, '');
    var fullRut = '';

    if (textoRut.length <= 1) {
        if (textoRut == 'k') {
            fullRut = 'K';
        } else {
            fullRut = textoRut;
        }
    } else {
        var cont = 0;
        for (var i = textoRut.length - 1; i >= 0; i--) {
            if (i == textoRut.length - 1) {
                if (textoRut.charAt(i) == 'k') {
                    fullRut = '-K';
                } else {
                    fullRut = '-' + textoRut.charAt(i);
                }
            } else {
                if (cont == 3) {
                    fullRut = '.' + fullRut;
                    cont = 0;
                }
                fullRut = textoRut.charAt(i) + fullRut;
                cont++;
            }
        }
    }
    return fullRut;
};

export const uploadFileToCloudStorage = async (file, route, name, bucketName) => {
	try {
		var formData = new FormData();
		formData.append("folder", route);
		formData.append("bucket", bucketName);
		formData.append("name", name);
		formData.append("file", file);

		let upload = await Axios.post('https://storage.cydingenieria.com/upload', formData);
		if (!Boolean(upload.status === 201 || upload.status === 200)) {
			throw new Error("Error al intentar subir el archivo.");
		}
		let uploadedFile = {
			mime_type: upload.data.content_type,
			nombre: name,
			// repositorio: `${upload.data.bucket}/${route}`,
            path: `${upload.data.bucket}/${route}`,
            repositorio: 'Cloud Storage',
			url: upload.data.pathname,
		}
		return uploadedFile;
	} catch (error) {
		console.error(error);
		throw error;
	}
}